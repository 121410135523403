import React, { useState } from 'react';
import { arrayOf, bool, number, shape, string, node } from 'prop-types';

const InterventionContext = React.createContext({
  currentIntervention: null,
  setCurrentInterventionData: null,
});

const { Provider, InterventionConsumer } = InterventionContext;

const InterventionProvider = ({ children, interventionData }) => {
  const [currentInterventionData, setCurrentInterventionData] = useState(interventionData);

  const state = {
    currentInterventionData,
    setCurrentInterventionData,
  };

  return <Provider value={state}>{children}</Provider>;
};

const valueShape = shape({
  id: string,
  key: string,
  type: string,
  text: string,
  color: string,
});

const pillShape = shape({
  text: string,
  color: string,
  values: arrayOf(valueShape),
  accessibility_text: string,
  font_size: string,
  font_weight: string,
  spacing_top: string,
  line_spacing_extra: number,
});

const titleShape = shape({
  text: string,
  font_size: string,
  font_weight: string,
  color: string,
  line_spacing_extra: number,
  values: arrayOf(valueShape),
  accessibility_text: string,
  is_new_buyer: bool,
});

const progressBarShape = shape({
  percent: number,
  color: string,
  accessibility_text: string,
});

const epigraphShape = shape({
  text: string,
  values: arrayOf(valueShape),
  accessibility_text: string,
});

const headerShape = shape({
  icon_id: string,
  picture: string,
  title: titleShape,
  progress_bar: progressBarShape,
  epigraph: epigraphShape,
  pills: arrayOf(pillShape),
});

InterventionProvider.propTypes = {
  children: node.isRequired,
  interventionData: arrayOf(
    shape({
      id: string,
      type: string,
      state: string,
      group_by: string,
      header: headerShape,
    }),
  ),
};

export { InterventionProvider, InterventionContext, InterventionConsumer };
