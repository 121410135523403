import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import Script from 'nordic/script';
import { escape } from 'html-escaper';

const GoogleTagManager = ({
  siteId,
  platform,
  results,
  cookies,
  queryInitialState,
  businessInitialState,
  pageVerticalInitialState,
}) => {
  const getResults = () => {
    const categories = [];
    const ids = [];

    for (let i = 0; i < 5; i += 1) {
      if (results[i]) {
        categories.push(results[i].category_id);
        ids.push(results[i].id);
      }
    }

    return { categories, ids };
  };

  const resultsMarketing = getResults();

  /* eslint-disable-next-line no-underscore-dangle */
  const d2id = cookies?._d2id ? cookies._d2id : '';
  const query = (queryInitialState && escape(queryInitialState.replace(/['"]+/g, ''))) || '';
  const business = businessInitialState || '';
  const pageVertical = pageVerticalInitialState || '';

  return (
    <Script priority={150}>
      {`


     var mktconfig = {
    'selector' : 'searchResults',
    'results': '${resultsMarketing.ids}',
    'categories': '${resultsMarketing.categories}',
    'siteId' : '${siteId}',
    'd2id': '${d2id}',
    'query': '${query}',
    'platform' : '${platform}',
    'business': '${business}',
    'pageVertical': '${pageVertical}'
        }

        var Search = Search || {};


        ;(function (Search, win, doc) {

          function MarketingTags(options) {
            this.platform = options.platform || '';
            this.siteId = options.siteId || '';
            this.items = options.results || [];
            this.categories = options.categories || [];
            this.d2id = options.d2id;
            this.query = options.query;
            this.business = options.business;
            this.pageVertical = options.pageVertical;

            if (!this.items || !this.platform || !this.siteId || !this.categories) { throw new Error('MarketingTags: error in incoming params')}

              this.init();

              return this;

          };

          MarketingTags.prototype.init = function () {
            var data = {}, categoriesIds = [];

            if (this.items) {
              data.items = this.items;
              categoriesIds = this.unique(this.categories);

              if(categoriesIds.length == 1){
                data.categoryId = categoriesIds
              }

              if (this.d2id) {
                data.d2id = this.d2id
              }

              if (this.query) {
                data.query = this.query;
              }

              if (this.business) {
                data.business = this.business;
              }

              if (this.pageVertical) {
                data.pageVertical = this.pageVertical;
              }

              this.start(data);
              this.createIframe();
            }
          };

          MarketingTags.prototype.createIframe = function () {
            var iframe = doc.createElement("iframe"),
                  scriptTag = doc.getElementsByTagName('script')[0],
              elem = iframe.frameElement || iframe;

                elem.style.width = 0;
              elem.style.height = 0;
              elem.style.border = 0;
              elem.style.position = 'absolute';


                iframe.src = this.url;
                scriptTag.parentNode.insertBefore(iframe, scriptTag);
          };

          MarketingTags.prototype.start = function (data) {
            var millisecondsInMinute = 60000
            var minuteInPartition = 60 * 5
            var currentMilliseconds = new Date().getTime()
            var currentSeconds = parseInt(currentMilliseconds / millisecondsInMinute)
            var timeHash = parseInt(currentSeconds/minuteInPartition)*minuteInPartition

            this.url = "https://http2.mlstatic.com/storage/tag-manager/" + this.siteId + ".html?timehash="+ timeHash +"&platform=" + this.platform + "#" + JSON.stringify(data);

          };

          MarketingTags.prototype.unique = function(array){
              var tmp = {},
                out = [];
              for(var i = 0, n = array.length; i < n; ++i){
                  if(!tmp[array[i]]) { tmp[array[i]] = true; out.push(array[i]); }
              }
              return out;
          }

            Search.MarketingTags = MarketingTags;

        })(Search, window, document);

          new Search.MarketingTags(mktconfig);
    `}
    </Script>
  );
};

GoogleTagManager.propTypes = {
  cookies: shape({}).isRequired,
  platform: string.isRequired,
  queryInitialState: string.isRequired,
  results: arrayOf(shape({})).isRequired,
  siteId: string.isRequired,
  businessInitialState: string.isRequired,
  pageVerticalInitialState: string.isRequired,
};

export default GoogleTagManager;
