import React from 'react';
import { arrayOf, shape, func, string } from 'prop-types';

import Tag from '@andes/tag';
import CarouselFree, { CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../link';

const namespace = 'ui-search-text-icon-filter-intervention';

const TextIconCarousel = ({ tags, Icon }) => (
  <div className={namespace}>
    <CarouselFree className={`${namespace}-carousel`}>
      {tags.map((tag) => (
        <CarouselFreeSlide key={tag.id} className={`${namespace}-item-slide`}>
          <Link className={`${namespace}__link`} href={tag.url} title={tag.label}>
            <Tag label={tag.label} asset={{ icon: <Icon /> }} />
          </Link>
        </CarouselFreeSlide>
      ))}
    </CarouselFree>
  </div>
);

TextIconCarousel.propTypes = {
  Icon: func.isRequired,
  tags: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
};

export default TextIconCarousel;
