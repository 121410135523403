import React from 'react';
import { shape, arrayOf, string } from 'prop-types';

import Search16 from '@andes/icons/Search16';

import TextIconCarousel from '../filter-intervention/text-icon-carousel.mobile';

const SeoTopKeywordsIntervention = ({ keywords, title }) => {
  const namespace = 'ui-search-seo-top-keywords-intervention';
  const icon = () => <Search16 />;

  return (
    <div className={`${namespace}__container`}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <TextIconCarousel tags={keywords} Icon={icon} />
    </div>
  );
};

SeoTopKeywordsIntervention.propTypes = {
  keywords: arrayOf(
    shape({
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
  title: string.isRequired,
};

export default SeoTopKeywordsIntervention;
