import { useContext } from 'react';

import { useSearch } from '../../hooks/context';
import { LayoutContext } from '../context/layout';

const useListingDisclaimer = () => {
  const { listing_disclaimer } = useSearch();
  const { currentLayout } = useContext(LayoutContext);

  if (listing_disclaimer) {
    listing_disclaimer.currentLayout = currentLayout;
  }

  return listing_disclaimer;
};

export default useListingDisclaimer;
