import get from 'lodash/get';

import { trackEvent as trackAnalyticsEvent } from '../../../lib/analytics';
import { trackEvent as trackMelidataEvent } from '../../../lib/melidata';

// Common variables
const ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
};

const ITEM_THRESHOLD_TYPE = {
  DEFAULT_THRESHOLD_LABEL: 'DEFAULT_THRESHOLD_LABEL',
  THRESHOLD_LABEL_FS_PROMISE: 'THRESHOLD_LABEL_FS_PROMISE',
};

const THRESHOLD_LABEL_ACTION = {
  CHANGE_THRESHOLD_PARTNER_TEXT: 'CHANGE_THRESHOLD_PARTNER_TEXT',
  CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT: 'CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT',
  CHANGE_THRESHOLD_SUPER_TEXT: 'CHANGE_THRESHOLD_SUPER_TEXT',
  CHANGE_THRESHOLD_PROMISE_SUPER_TEXT: 'CHANGE_THRESHOLD_PROMISE_SUPER_TEXT',
};

// Common functions
const sendTracks = (response, itemId) => {
  if (response.tracks) {
    const { melidata_track, analytics_track } = response.tracks;

    // Process melidata tracks if they are present
    if (melidata_track) {
      melidata_track.clean = true;

      if (itemId) {
        melidata_track.event_data.item_id = itemId;
      }

      trackMelidataEvent(melidata_track);
    }

    // Process analytics tracks if they are present
    if (analytics_track) {
      trackAnalyticsEvent(analytics_track);
    }
  }
};

const setMelidataInfo = (action) => (quantity, tracks) => {
  tracks.melidata_track.event_data.items[0].quantity = quantity;
  tracks.melidata_track.path = get(tracks.path, action, ACTION.ADD);
  tracks.melidata_track.clean = true;
  trackMelidataEvent(tracks.melidata_track);
};

const setMelidataInfoAdd = setMelidataInfo(ACTION.ADD);
const setMelidataInfoRemove = setMelidataInfo(ACTION.REMOVE);

const requestQueueDefault = {
  pendingRequest: null,
  isRequestInProgress: false,
};

export {
  sendTracks,
  setMelidataInfoAdd,
  setMelidataInfoRemove,
  requestQueueDefault,
  ACTION,
  ITEM_THRESHOLD_TYPE,
  THRESHOLD_LABEL_ACTION,
};
