import React, { useContext, useRef, useState } from 'react';
import { string, shape, number, func } from 'prop-types';

import classnames from 'classnames';
import { Card as Polycard } from '@polycard/card';

import usePolyCardInteraction from '../../../hooks/use-polycard-interaction';
import { StaticPropsContext } from '../../context/static-props';
import { DEVICE_TYPES } from '../../../constants';

const OneColumnItemLayoutPolycard = ({ item, index, layout, onMount }) => {
  const namespace = 'ui-search-result';
  const { deviceType, vertical, site } = useContext(StaticPropsContext);
  const cardRef = useRef(null);
  const isGrid = layout === 'grid';
  const isCPG = vertical === 'CPG';
  const isMobile = deviceType === DEVICE_TYPES.MOBILE;
  const layoutIndex = isGrid || isMobile ? index : index + 1;
  const [mouseEntered, setMouseEntered] = useState(false);

  const { hasFooter, hasVariations, itemState, loadImagesCarousel, handleBookmark } = usePolyCardInteraction({
    item,
    cardRef,
    onMount,
    isMobile,
    layout,
  });

  const isResizable = !isMobile && isGrid;

  const hiddenPicture = hasVariations && itemState.pictures.pictures.length > 1;

  const className = classnames({
    [`${namespace}`]: isMobile && !isCPG,
    [`${namespace}__wrapper`]: !isMobile,
    [`${namespace}__wrapper--hidden_picture`]: hiddenPicture,
    [`${namespace}__wrapper--large`]: (hasFooter && isResizable) || (hasVariations && isResizable),
    '': isCPG,
  });

  const polycardClass = classnames({
    'mshops-search-polycard': site === 'MS',
  });

  const handleMouseEnter = () => {
    if (!mouseEntered && hasVariations) {
      loadImagesCarousel(itemState.pictures.variation_id, true);
      setMouseEntered(true);
    } else if (!hasVariations) {
      loadImagesCarousel(itemState.pictures.variation_id, true);
    }
  };

  return (
    <div className={className} ref={cardRef}>
      <Polycard
        key={`${itemState?.metadata?.id}`}
        polycard={itemState}
        cardNumber={layoutIndex}
        bookmarkAction={handleBookmark}
        pickerAction={loadImagesCarousel}
        onMouseEnter={isGrid && handleMouseEnter}
        className={polycardClass}
      />
    </div>
  );
};

OneColumnItemLayoutPolycard.propTypes = {
  index: number.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
  onMount: func,
};

export default OneColumnItemLayoutPolycard;
