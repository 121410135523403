import React, { useState } from 'react';
import { string, shape, arrayOf } from 'prop-types';

import classnames from 'classnames';
import { Card } from '@andes/card';
import { Accordion } from '@andes/accordion';

import SmartTabContent from './smart-tab-content';

const namespace = 'ui-search-seo-smart-tabs';

const SeoSmartTabs = ({ title = '', tabs = [], tracks = {} }) => {
  const [activeIds, setActiveIds] = useState([0]);

  return (
    tabs?.length > 0 && (
      <div className={`${namespace}__container`}>
        <h3 className={`${namespace}__title`}>{title}</h3>
        <Card className={classnames(namespace)} shadow="outline">
          <div className={`${namespace}__card-contend`}>
            {tabs.map((tab, i) => (
              <Accordion
                key={tab.title}
                title={tab.title}
                showDivider={i + 1 < tabs.length}
                expanded={activeIds.includes(i)}
                onChange={() => setActiveIds(activeIds.includes(i) ? [] : [i])}
              >
                <SmartTabContent links={tab.links} tracks={tracks} />
              </Accordion>
            ))}
          </div>
        </Card>
      </div>
    )
  );
};

SeoSmartTabs.propTypes = {
  tabs: arrayOf(
    shape({
      title: string,
      links: arrayOf(
        shape({
          url: string,
          label: string,
        }),
      ),
    }),
  ),
  title: string.isRequired,
};

export default SeoSmartTabs;
