import React from 'react';
import { string, shape, arrayOf } from 'prop-types';

import { trackEvent } from '../../lib/melidata';

const namespace = 'ui-search-smart-tab-content';

const handleClick = (label, tracks) => {
  const { melidata_track: melidataTrack } = tracks || {};
  const { path, type, event_data: eventData = {} } = melidataTrack || {};

  if (melidataTrack) {
    trackEvent({
      path,
      type,
      event_data: {
        ...eventData,
        label,
      },
    });
  }
};

const SmartTabContent = ({ links = [], tracks = {} }) => (
  <div className={`${namespace}__container`}>
    {links.map(({ label = '', url = '' }) => (
      <a
        key={label.replaceAll(' ', '')}
        className={`${namespace}__link`}
        href={url}
        onClick={() => {
          handleClick(label, tracks);
        }}
      >
        {label}
      </a>
    ))}
  </div>
);

SmartTabContent.propTypes = {
  links: arrayOf(
    shape({
      label: string,
      url: string,
    }),
  ).isRequired,
  tracks: shape({
    melidata_track: shape({
      path: string,
      type: string,
      event_data: shape({}),
    }),
  }),
};

export default SmartTabContent;
