import React from 'react';
import { shape, string, arrayOf, bool, number, oneOfType } from 'prop-types';

import AndesPagination from '@andes/pagination';

import { Container } from './components';
import { formatUrl } from './helpers';

const namespace = 'ui-search-andes-pagination';

const Pagination = ({ paginationNodes }) => {
  const actualNode = paginationNodes.find((node) => node.is_actual_page);
  const pageSelected = Number(actualNode.value);
  const lastNode = paginationNodes[paginationNodes.length - 1];
  const lastPage = Number(lastNode.value);

  return (
    <AndesPagination
      className={namespace}
      pageSelected={pageSelected}
      pageQuantity={lastPage}
      href=""
      formatUrl={formatUrl(paginationNodes, 0, lastPage)}
    />
  );
};

Pagination.propTypes = {
  paginationNodes: arrayOf(
    shape({
      value: oneOfType([number, string]),
      url: string,
      is_actual_page: bool,
    }),
  ).isRequired,
};

const DesktopPagination = () => <Container Content={Pagination} />;

export default DesktopPagination;
