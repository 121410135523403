import React, { useContext } from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';

import { useStaticProps } from '../context/static-props';
import StyledLabelWrapper from '../styled-label/styled-label';
import { FreeShippingThContext } from '../context/free-shipping-th';
import { STACK } from '../layout/renderer/constants';
import { DEVICE_TYPES } from '../../constants';
import { ITEM_THRESHOLD_TYPE } from '../card/add-to-cart/utils';

const namespace = 'ui-search-threshold-label';
const FULL_SUPER = 'FULL_SUPER';
const PARTNER = 'PARTNER';
const { THRESHOLD_LABEL_FS_PROMISE } = ITEM_THRESHOLD_TYPE;

const FreeShippingThresholdText = ({ groupBy, layout, thresholdLabel, thresholdLabelState }) => {
  const { font_size: size, id, ...rest } = thresholdLabel;
  const { thresholdState, thresholdDispatch } = useContext(FreeShippingThContext);
  const {
    firstLoadSuper,
    firstLoadPartner,
    thresholdSuperLabel,
    thresholdPartnerLabel,
    superShippingPromiseLabel,
    partnerShippingPromiseLabel,
  } = thresholdState;

  const { deviceType } = useStaticProps();
  const isStack = layout === STACK;
  const isDesktop = deviceType === DEVICE_TYPES.DESKTOP;
  const isFsPromise = id === THRESHOLD_LABEL_FS_PROMISE;

  if (firstLoadSuper && groupBy === FULL_SUPER) {
    thresholdDispatch({
      type: 'FIRST_LOAD_SUPER',
      payload: { threshold_label_state: thresholdLabelState },
    });
  } else if (firstLoadPartner && groupBy === PARTNER) {
    thresholdDispatch({
      type: 'FIRST_LOAD_PARTNER',
      payload: { threshold_label_state: thresholdLabelState },
    });
  }

  if (groupBy === FULL_SUPER) {
    return thresholdSuperLabel === '' && thresholdLabel ? (
      <StyledLabelWrapper
        className={classnames(`${namespace}-initial`, {
          [`${namespace}-initial--stack`]: isStack,
          [`${namespace}-initial--grid`]: !isStack && isDesktop,
        })}
        as="p"
        size={size}
        {...rest}
      />
    ) : (
      <StyledLabelWrapper
        key={isFsPromise ? superShippingPromiseLabel?.text : thresholdSuperLabel?.text}
        className={namespace}
        as="p"
        size={size}
        {...(isFsPromise ? superShippingPromiseLabel : thresholdSuperLabel)}
      />
    );
  }

  return thresholdPartnerLabel === '' && thresholdLabel ? (
    <StyledLabelWrapper
      className={classnames(`${namespace}-initial`, {
        [`${namespace}-initial--stack`]: isStack,
        [`${namespace}-initial--grid`]: !isStack && isDesktop,
      })}
      as="p"
      size={size}
      {...rest}
    />
  ) : (
    <StyledLabelWrapper
      key={isFsPromise ? partnerShippingPromiseLabel?.text : thresholdPartnerLabel?.text}
      className={namespace}
      as="p"
      size={size}
      {...(isFsPromise ? partnerShippingPromiseLabel : thresholdPartnerLabel)}
    />
  );
};

FreeShippingThresholdText.propTypes = {
  groupBy: string,
  layout: string,
  thresholdLabel: shape({
    id: string,
    text: string,
    color: string,
    font_size: string,
    font_weight: string,
  }).isRequired,
  thresholdLabelState: string,
};

FreeShippingThresholdText.defaultProps = {
  thresholdLabel: {},
};

export default FreeShippingThresholdText;
