import React, { useState, useEffect } from 'react';
import { string, func, shape } from 'prop-types';

import { Image } from 'nordic/image';
import get from 'lodash/get';
import classnames from 'classnames';
import { Dropdown } from '@andes/dropdown';
import { ListItem } from '@andes/list';

import Link from '../../link';
import { GRID } from '../../layout/renderer/constants';

const namespace = 'ui-search-variations-picker';

const getThumbnailsDisplayConfig = (thumbnailsToShow, layout, variationsTotal, windowWidth) => {
  const showAllThumbnailsConfig = { variationsQuantity: variationsTotal };

  const layoutConfig = get(thumbnailsToShow, layout);

  if (!layoutConfig) {
    return showAllThumbnailsConfig;
  }

  const layoutLimitResolution = layoutConfig.limit_resolution;

  if (!layoutLimitResolution || windowWidth < layoutLimitResolution) {
    return {
      variationsQuantity: layoutConfig.max_thumbnails,
      showMoreText: layoutConfig.show_more_text,
    };
  }

  const defaultConfig = get(thumbnailsToShow, 'default');

  if (defaultConfig) {
    return {
      variationsQuantity: defaultConfig.max_thumbnails,
      showMoreText: defaultConfig.show_more_text,
    };
  }

  return showAllThumbnailsConfig;
};

const VariationsPicker = ({
  layout,
  itemContext: { item, variations, selectedVariation },
  label,
  thumbnails_to_show,
  normalizePictureData,
  renderOptions,
}) => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const resizeHandler = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const { variationsQuantity, showMoreText } = getThumbnailsDisplayConfig(
    thumbnails_to_show,
    layout,
    variations.length,
    windowWidth,
  );
  const variationsToShow = variations.filter((variation, index) => index < variationsQuantity);

  const isViewMoreOption = (index) => showMoreText && index === variationsQuantity - 1;

  const renderGridVariationOption = (variation, index) => {
    const variationImage = (
      <Image
        className={`${namespace}__variation-image`}
        {...normalizePictureData(variation.thumbnail, get(variation, 'thumbnail.tags.alt'), renderOptions)}
        alt={get(variation, 'thumbnail.tags.alt')}
      />
    );

    if (!isViewMoreOption(index)) {
      return (
        <li
          className={classnames(`${namespace}__variation`, {
            [`${namespace}__variation--selected`]: variation.id === selectedVariation.id,
          })}
        >
          <Link href={variation.item.permalink} onClick={(e) => e.stopPropagation()} keepLayout={false}>
            {variationImage}
          </Link>
        </li>
      );
    }

    return (
      <li className={`${namespace}__variation`} style={{ opacity: 1 }}>
        {variationImage}
        <Link className={`${namespace}__view-more`} href={item.permalink} keepLayout={false} title={label}>
          {showMoreText}
        </Link>
      </li>
    );
  };

  const renderStackVariationOption = (variation, index) => {
    const optionProps = {
      value: variation.item.permalink,
      primary: variation.name,
      selected: variation.id === selectedVariation.id,
      leftContent: {
        src: get(variation, 'thumbnail.retina'),
        title: get(variation, 'thumbnail.tags.alt'),
      },
    };

    if (!isViewMoreOption(index)) {
      return <ListItem {...optionProps} />;
    }

    return (
      <ListItem {...optionProps} style={{ opacity: 1 }}>
        <Link
          className={`${namespace}__view-more`}
          href={item.permalink}
          onClick={(e) => e.stopPropagation()}
          keepLayout={false}
          title={optionProps?.leftContent?.title}
        >
          {showMoreText}
        </Link>
      </ListItem>
    );
  };

  if (layout === GRID) {
    return (
      <div className={`${namespace} ${namespace}--grid`}>
        <ul className={`${namespace}__variations-list`}>
          {variationsToShow.map((variation, index) => renderGridVariationOption(variation, index))}
        </ul>
      </div>
    );
  }

  return (
    <div className={`${namespace} ${namespace}--stack`}>
      <p className={`${namespace}__label`}>{`${label.text}:`}</p>
      <Dropdown
        label={selectedVariation.name}
        renderCustomTrigger={selectedVariation.name}
        value={selectedVariation.id}
        onChange={(_, permalink) => {
          window.location.href = permalink;
        }}
        size="small"
        menuAlignment="bottom"
        width={[100]}
        closable={false}
      >
        {variationsToShow.map((variation, index) => renderStackVariationOption(variation, index))}
      </Dropdown>
    </div>
  );
};

VariationsPicker.propTypes = {
  adaptPictures: func.isRequired,
  className: string,
  handleSelectVariation: func.isRequired,
  itemContext: shape({}).isRequired,
  label: shape({}).isRequired,
  layout: string.isRequired,
  normalizePictureData: shape({}).isRequired,
  renderOptions: shape({}).isRequired,
  selectedVariation: shape().isRequired,
  thumbnails_to_show: string,
};

VariationsPicker.defaultProps = {
  className: null,
};

export default VariationsPicker;
