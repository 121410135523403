/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useCallback, startTransition } from 'react';
import { arrayOf, objectOf, shape, string, any, bool, number } from 'prop-types';

import debounce from 'lodash/debounce';

import { SnackbarContext } from '../../../context/snackbar';
import { FullFreeshippingContext } from '../../../context/full-freeshipping';
import { FreeShippingThContext } from '../../../context/free-shipping-th';
import { InterventionContext } from '../../../context/intervention';
import { LayoutContext } from '../../../context/layout';
import ServiceAddToCart from '../service';
import PresentationAddToCart from '../presentation/add-to-cart';
import { sendTracks, requestQueueDefault, ACTION, ITEM_THRESHOLD_TYPE, THRESHOLD_LABEL_ACTION } from '../utils';
import { useSearch } from '../../../../hooks/context';
import { StaticPropsContext } from '../../../context/static-props';
import useSuggestions from '../hooks/useSuggestions';
import useEshopsEnvironment from '../../../eshop-environment/use-eshops-environment';

const { ADD, REMOVE } = ACTION;
const PARTNER = 'PARTNER';
const FULL_SUPER = 'FULL_SUPER';
const NN_SUPER_SITES_ID = ['MLB', 'MLM', 'MLC'];
const { DEFAULT_THRESHOLD_LABEL, THRESHOLD_LABEL_FS_PROMISE } = ITEM_THRESHOLD_TYPE;
const {
  CHANGE_THRESHOLD_PARTNER_TEXT,
  CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT,
  CHANGE_THRESHOLD_SUPER_TEXT,
  CHANGE_THRESHOLD_PROMISE_SUPER_TEXT,
} = THRESHOLD_LABEL_ACTION;

const updateCartFreya = () => {
  if (window?.freya) {
    window.freya.emit('cart:refresh');
  }
};

const DEFAULT_DELAY = 500;

const getSiteFromItem = (itemId) => {
  if (itemId && typeof itemId === 'string') {
    return itemId.slice(0, 3);
  }

  return null;
};

const AddToCart = ({
  action,
  availableQuantity,
  cart_request_delay,
  cart_request_initial_delay,
  category,
  isCpg,
  itemId,
  label,
  label_count,
  labelMinQuantity,
  minimumQuantity,
  productId,
  quantity,
  tracks,
  type,
  variation_id,
  weight_data,
  inventoryId,
  groupBy,
  threshold,
  should_call_suggestions,
  isAd,
  permalink,
  viewMode,
}) => {
  const {
    getCPGInfo,
    getPreloadedLabelCount,
    getQuantityCart,
    getQuantityCartPrevious,
    getQuantityCartRequested,
    setLabelCount,
    setLabelCountValues,
    setPreloadedLabelCount,
    setQuantityCart,
    setQuantityCartPrevious,
    setQuantityCartRequested,
    sameItemList,
    setSameItemList,
    setPartialPreloadedLabelCount,
  } = useSearch();
  const { getSuggestions } = useSuggestions();

  const { queryParams } = useContext(StaticPropsContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const { setFullFreeshipping, isVisible, setFullFreeShippingLoading, showShimmer } =
    useContext(FullFreeshippingContext);
  const { setCurrentInterventionData } = useContext(InterventionContext);
  const { isEshopsEnvironment } = useEshopsEnvironment();
  const { thresholdState, thresholdDispatch } = useContext(FreeShippingThContext) || {};
  const { currentLayout } = React.useContext(LayoutContext);
  const { freeShippingSuper, freeShippingPartner } = thresholdState;
  const [itemCount, setItemCount] = useState(quantity);
  const [maxStock, setMaxStock] = useState(availableQuantity);
  const [firstRequest, setFirstRequest] = useState(true);
  const [requestQueue, setRequestQueue] = useState({ ...requestQueueDefault });
  const itemQuantity = itemId in sameItemList ? sameItemList[`${itemId}`].quantity : itemCount;
  const labelQuantity = itemId in sameItemList ? sameItemList[`${itemId}`].label_count : label_count;
  const itemMaxStock = itemId in sameItemList ? sameItemList[`${itemId}`].max_stock : maxStock;
  const addEnabled = itemQuantity < itemMaxStock;

  const getLabelsForQuantity = useCallback(
    (newQuantity) => {
      const cpgInfo = getCPGInfo(itemId);

      let newLabels = getPreloadedLabelCount(itemId, newQuantity);

      if (!newLabels) {
        if (cpgInfo?.label_count?.text.includes('{quantity_text}') && !cpgInfo.preloaded_label_counts) {
          newLabels = cpgInfo.label_count;

          const value = cpgInfo.label_count.values.find((val) => val.key === 'quantity_text');

          value.text = newQuantity.toString();
        }
      }

      return newLabels;
    },
    [getCPGInfo, getPreloadedLabelCount],
  );

  const apiError = (snackbar) => {
    let previousQuantity = parseInt(getQuantityCartPrevious(itemId), 10);

    if (Number.isNaN(previousQuantity)) {
      previousQuantity = 0;
    }

    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: previousQuantity,
        },
      });
    } else {
      setItemCount(previousQuantity);
    }

    setQuantityCart(itemId, previousQuantity);
    setQuantityCartRequested(itemId, null);

    setSnackbar({
      color: 'red',
      message: snackbar?.text ? snackbar.text : 'No se pudo actualizar la cantidad.', // TODO FIXME translate traducir esto
      className: 'ui-search-snackbar-add-cart--error',
    });

    setTimeout(() => {
      setSnackbar(null);
    }, 5000);
  };

  const updateItemsFromCart = useCallback(
    (newQuantity) => {
      if (itemId in sameItemList) {
        setSameItemList({
          ...sameItemList,
          [itemId]: {
            ...sameItemList[`${itemId}`],
            quantity: newQuantity,
          },
        });
      } else {
        setItemCount(newQuantity);
      }
    },
    [setSameItemList, setItemCount],
  );

  const processRequest = (callback) => {
    requestQueue.isRequestInProgress = false;

    if (requestQueue.pendingRequest) {
      requestQueue.pendingRequest();
      requestQueue.pendingRequest = null;
      setRequestQueue(requestQueue);
    } else {
      callback();
      setRequestQueue(requestQueue);
    }
  };

  const checkAvailableQuantity = (finalQuantity) => {
    const newLabels = getLabelsForQuantity(finalQuantity);

    if (newLabels) {
      setLabelCount(itemId, newLabels);
    }

    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: finalQuantity,
          max_stock: finalQuantity,
          label_count: newLabels,
        },
      });
    } else {
      setMaxStock(finalQuantity);
      setItemCount(finalQuantity);
    }

    setQuantityCart(itemId, finalQuantity);
    setQuantityCartPrevious(itemId, finalQuantity);
  };

  const getThresholdLabelContent = (cartInfo) => {
    const thresholdLabels = cartInfo?.threshold_labels || [];
    const hasThresholdLabels = thresholdLabels.length > 0;
    if (hasThresholdLabels) {
      const thresholdLabelDefault = thresholdLabels.find((th) => th.id === DEFAULT_THRESHOLD_LABEL);
      const thresholdLabelPromise = thresholdLabels.find((th) => th.id === THRESHOLD_LABEL_FS_PROMISE);
      return { hasThresholdLabels, thresholdLabelDefault, thresholdLabelPromise };
    }

    const thresholdLabel = cartInfo?.threshold_label;
    return {
      hasThresholdLabels,
      thresholdLabel,
    };
  };

  const changeFreeShippingText = (cartInfo = {}) => {
    const { group_by } = cartInfo;
    const { hasThresholdLabels, thresholdLabel, thresholdLabelDefault, thresholdLabelPromise } =
      getThresholdLabelContent(cartInfo);

    const getLabels = () => ({
      defaultLabel: hasThresholdLabels ? thresholdLabelDefault : thresholdLabel,
      fsPromiseLabel: hasThresholdLabels ? thresholdLabelPromise : thresholdLabel,
    });

    const dispatchThresholdChange = (defaultThresholdType, promiseThresholdType) => {
      const { defaultLabel, fsPromiseLabel } = getLabels();

      thresholdDispatch({
        type: defaultThresholdType,
        payload: {
          ...cartInfo,
          threshold_labels: defaultLabel,
        },
      });

      thresholdDispatch({
        type: promiseThresholdType,
        payload: {
          ...cartInfo,
          threshold_labels: fsPromiseLabel,
        },
      });
    };

    const dispatchPartnerChange = () => {
      dispatchThresholdChange(CHANGE_THRESHOLD_PARTNER_TEXT, CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT);
    };

    const dispatchSuperChange = () => {
      dispatchThresholdChange(CHANGE_THRESHOLD_SUPER_TEXT, CHANGE_THRESHOLD_PROMISE_SUPER_TEXT);
    };

    if (group_by === PARTNER) {
      dispatchPartnerChange();
    } else if (group_by === FULL_SUPER) {
      dispatchSuperChange();
    }
  };

  const a2cServiceHooks = {
    apiError,
    checkAvailableQuantity,
    changeFreeShippingText,
    processRequest,
    setFirstRequest,
    setFullFreeShippingLoading,
    setFullFreeshipping,
    setItemCount: updateItemsFromCart,
    setLabelCountValues,
    setMaxStock,
    setPreloadedLabelCount,
    setQuantityCartRequested,
    setPartialPreloadedLabelCount,
    setCurrentInterventionData,
    setQuantityCartPrevious,
  };

  const callServiceAddToCart = () => {
    const newQuantity = getQuantityCart(itemId);
    const previousQuantity = parseInt(getQuantityCartPrevious(itemId), 10);

    if (
      !isEshopsEnvironment &&
      !isVisible &&
      showShimmer &&
      NN_SUPER_SITES_ID.includes(getSiteFromItem(itemId).toUpperCase())
    ) {
      setFullFreeShippingLoading(true);
      setFullFreeshipping({ key: Math.floor(Math.random() * 1000) });
    }

    ServiceAddToCart(
      itemId,
      category,
      variation_id,
      availableQuantity,
      newQuantity,
      isCpg,
      productId,
      inventoryId,
      previousQuantity <= newQuantity ? ADD : REMOVE,
      a2cServiceHooks,
      queryParams,
      weight_data,
      requestQueue || requestQueueDefault,
      tracks,
      updateCartFreya,
      firstRequest,
      groupBy,
      threshold,
      viewMode,
      isEshopsEnvironment,
      isAd,
      permalink,
      previousQuantity,
    );
  };

  const getDelay = () => {
    if (firstRequest) {
      return cart_request_initial_delay || cart_request_delay || DEFAULT_DELAY;
    }

    const quantityNew = getQuantityCart(itemId);
    const alreadyRequested = getQuantityCartRequested(itemId);

    return (
      (quantityNew === 1 && (alreadyRequested === 0 || !alreadyRequested)
        ? cart_request_initial_delay
        : cart_request_delay) || DEFAULT_DELAY
    );
  };

  const debouncedCartService = useCallback(debounce(callServiceAddToCart, getDelay()), [
    itemId,
    firstRequest,
    freeShippingSuper,
    freeShippingPartner,
    isVisible,
  ]);

  const changeCart = useCallback(() => {
    const newQuantity = getQuantityCart(itemId);

    if (should_call_suggestions) {
      getSuggestions(category, itemQuantity, itemId, newQuantity, queryParams);
    }

    debouncedCartService();
  }, [
    getQuantityCart,
    debouncedCartService,
    getSuggestions,
    category,
    itemQuantity,
    itemId,
    queryParams,
    should_call_suggestions,
  ]);

  const updateQuantity = useCallback(
    (newQuantity) => {
      const newLabels = getLabelsForQuantity(newQuantity);

      if (newLabels) {
        setLabelCount(itemId, newLabels);
      }

      startTransition(() => {
        setQuantityCartPrevious(itemId, itemQuantity);
        updateItemsFromCart(newQuantity);

        setQuantityCart(itemId, newQuantity);

        if (itemId in sameItemList) {
          setSameItemList({
            ...sameItemList,
            [itemId]: {
              ...sameItemList[`${itemId}`],
              quantity: newQuantity,
              label_count: newLabels,
            },
          });
        }

        changeCart();
      });
    },
    [getLabelsForQuantity, setQuantityCartPrevious, updateItemsFromCart, setQuantityCart, setSameItemList, changeCart],
  );

  const handleAddToCartDelete = useCallback(
    (e) => {
      e.preventDefault();

      const itemMinusOne = parseInt(itemQuantity, 10) - 1;

      if (minimumQuantity > 0 && itemMinusOne < minimumQuantity) {
        updateQuantity(0);
      } else {
        updateQuantity(itemMinusOne);
      }
    },
    [updateQuantity, itemQuantity, minimumQuantity, sameItemList],
  );

  const handleAddToCartPut = useCallback(
    (e) => {
      e.preventDefault();

      if (addEnabled) {
        if (minimumQuantity > 0 && itemQuantity < minimumQuantity) {
          if (minimumQuantity > maxStock) {
            updateQuantity(maxStock);
          } else {
            updateQuantity(minimumQuantity);
          }
        } else {
          const itemPlusOne = parseInt(itemQuantity, 10) + 1;

          updateQuantity(itemPlusOne);
        }
      }
    },
    [itemQuantity, minimumQuantity, addEnabled, maxStock, sameItemList],
  );

  return (
    <PresentationAddToCart
      action={action}
      handleAddToCartDelete={handleAddToCartDelete}
      handleAddToCartPut={handleAddToCartPut}
      itemCount={itemQuantity}
      itemId={itemId}
      label={label}
      labelCount={labelQuantity}
      labelMinQuantity={labelMinQuantity}
      maxValue={itemMaxStock}
      sendButtonTracks={sendTracks}
      type={type}
      permalink={permalink}
      viewMode={viewMode === 'intervention' ? viewMode : currentLayout?.id}
    />
  );
};

AddToCart.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    url: string,
  }).isRequired,
  availableQuantity: number,
  cart_request_delay: number,
  cart_request_initial_delay: number,
  category: string.isRequired,
  groupBy: string,
  inventoryId: string,
  isAd: bool,
  isCpg: bool,
  itemId: string.isRequired,
  label: string.isRequired,
  label_count: objectOf(any),
  labelMinQuantity: shape({
    color: string,
    text: string,
  }),
  minimumQuantity: number,
  permalink: string,
  productId: string,
  quantity: number.isRequired,
  should_call_suggestions: bool,
  threshold: number,
  tracks: shape({
    melidata_track: objectOf(any),
  }).isRequired,
  type: string.isRequired,
  variation_id: string,
  viewMode: string,
  weight_data: arrayOf(shape()),
};

AddToCart.defaultProps = {
  availableQuantity: 0,
  cart_request_delay: null,
  cart_request_initial_delay: null,
  isCpg: false,
  label_count: {},
  labelMinQuantity: undefined,
  minimumQuantity: 0,
  productId: null,
  weight_data: undefined,
  viewMode: null,
};

export default AddToCart;
