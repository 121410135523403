import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, node, shape, string } from 'prop-types';

import get from 'lodash/get';

import { trackLayoutChangeEvent } from '../../../lib/tracking';
import { LAYOUTS } from '../../../constants';

const LayoutContext = React.createContext('');
const { Provider, Consumer: LayoutConsumer } = LayoutContext;

const getNextLayout = (currentLayoutId, viewsList) => {
  const index = viewsList.findIndex((l) => l.id === currentLayoutId);

  return index === viewsList.length - 1 ? viewsList[0] : viewsList[index + 1];
};

const LayoutProvider = ({ children, layout, history }) => {
  const initialLayout = useRef(layout.current);
  const initialNextLayout = useRef(getNextLayout(layout.current, layout.views).id);
  const [currentLayout, setCurrentLayout] = useState(initialLayout.current);
  const [nextLayout, setNextLayout] = useState(initialNextLayout.current);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        setCurrentLayout(get(location, 'state.layoutId', initialLayout.current));
        setNextLayout(get(location, 'state.nextLayoutId', initialNextLayout.current));
      }
    });

    return () => unlisten();
  }, [history]);

  function getLayoutById(layoutId) {
    return layout.views.find((it) => it.id === layoutId);
  }

  function onChangeLayout(id) {
    // Si recibe id lo llama desktop, caso contrario mobile
    const newLayout = id ? getLayoutById(id) : getNextLayout(currentLayout, layout.views);
    const newNextLayout = getNextLayout(nextLayout, layout.views);

    const pathname = newLayout.url_path;

    setCurrentLayout(newLayout.id);
    setNextLayout(newNextLayout.id);
    trackLayoutChangeEvent(layout.track, newLayout.id);

    history.push({
      pathname: encodeURI(pathname),
      search: history.location.search,
      state: { layoutId: newLayout.id, nextLayoutId: newNextLayout.id },
    });
  }

  const state = {
    currentLayout: getLayoutById(currentLayout),
    nextLayout: getLayoutById(nextLayout),
    onChangeLayout,
    initialLayout: initialLayout.current,
    initialNextLayout: initialNextLayout.current,
    views: layout.views,
    isMapLayout: currentLayout === LAYOUTS.TYPE_MAP,
  };

  return <Provider value={state}>{children}</Provider>;
};

LayoutProvider.propTypes = {
  children: node.isRequired,
  history: shape({
    push: func.isRequired,
    listen: func.isRequired,
  }).isRequired,
  layout: shape({
    current: string,
    views: arrayOf(
      shape({
        id: string,
        label: string,
        url_host: string,
        url_path: string,
      }),
    ),
  }).isRequired,
};

export { LayoutContext, LayoutProvider, LayoutConsumer };
