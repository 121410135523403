import React from 'react';

import { StaticPropsContext } from '../context/static-props';
import { LayoutContext } from '../context/layout';
import { linkPropTypes } from './constants';
import { buildNewHref, buildProps } from './link-utils';

const namespace = 'ui-search-link';

const NO_INDEX_PARAM = '_NoIndex_True';
const shouldAddNoFollow = (href) => href && href.indexOf(NO_INDEX_PARAM) !== -1;

const Link = ({
  className = '',
  href = '',
  linkTag = 'a',
  title = null,
  role = null,
  onClick = null,
  isInternal = true,
  keepLayout = true,
  ariaLabel = null,
  ariaLabelledBy = null,
  tracks = null,
  target = null,
  children,
  isAd,
  adLabel,
}) => {
  const { queryParams } = React.useContext(StaticPropsContext);
  const { currentLayout, initialLayout } = React.useContext(LayoutContext);
  const newHref = buildNewHref({ href, isInternal, queryParams, keepLayout, currentLayout, initialLayout });
  const addNoFollow = shouldAddNoFollow(href) || adLabel;
  const linkProps = buildProps({
    linkTag,
    newHref,
    href,
    className,
    namespace,
    title,
    role,
    onClick,
    target,
    ariaLabel,
    ariaLabelledBy,
    tracks,
    shouldAddNoFollow: addNoFollow,
    isAd,
  });

  return React.createElement(linkTag, { ...linkProps }, children);
};

Link.propTypes = linkPropTypes;

export default Link;
