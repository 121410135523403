import React, { useState } from 'react';
import { node, object, bool } from 'prop-types';

const FullFreeshippingContext = React.createContext({
  currentFullFreeshipping: null,
  setFullFreeshipping: null,
});
const { Provider, Consumer: FullFreeshippingConsumer } = FullFreeshippingContext;

const FullFreeshippingProvider = ({ children, value = '', shimmer, shimmerAction }) => {
  const [currentFullFreeshipping, setFullFreeshipping] = useState(value);
  const [fullOnlyFirstFreeshipping, setFullOnlyFirstFreeshipping] = useState(null);
  const [isLoading, setFullFreeShippingLoading] = useState(false);
  const [isVisible, setFullFreeShippingVisibility] = useState(false);

  const state = {
    currentFullFreeshipping,
    setFullFreeshipping,
    fullOnlyFirstFreeshipping,
    setFullOnlyFirstFreeshipping,
    isLoading,
    setFullFreeShippingLoading,
    isVisible,
    setFullFreeShippingVisibility,
    showShimmer: shimmer,
    freeshippingSnackbarDuration: value ? value.duration : 'SHORT',
    showShimmerAction: shimmerAction,
  };

  return <Provider value={state}>{children}</Provider>;
};

FullFreeshippingProvider.propTypes = {
  children: node.isRequired,
  shimmer: bool,
  shimmerAction: bool,
  value: object,
};

export { FullFreeshippingContext, FullFreeshippingProvider, FullFreeshippingConsumer };
