import React from 'react';
import { string } from 'prop-types';

import useFilterSearch from '../../hooks/use-filter-search';
import Link from './link';
import { linkPropTypes } from './constants';
import { useSearch, useFilters } from '../../hooks/context';
import { LAYOUTS, FILTERS } from '../../constants';
import useMapConfig from '../map/hooks/use-map-config';
import { trackClickEvent } from '../map/track';

const FilterLink = ({ href = '', name, children, filterId, ...props }) => {
  const { OPERATION, STATE, CITY, NEIGHBORHOOD, CATEGORY, OPERATION_SUBTYPE } = FILTERS;
  const { layout_options } = useSearch();
  const filterSearch = useFilterSearch(href, filterId);
  const { changeSecondaryFilter } = useFilters();
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const handleClick = (event) => {
    filterSearch(event);
    trackClickEvent(null, track.melidata);

    if (
      filterId === OPERATION ||
      filterId === STATE ||
      filterId === CITY ||
      filterId === NEIGHBORHOOD ||
      filterId === CATEGORY ||
      filterId === OPERATION_SUBTYPE
    ) {
      changeSecondaryFilter(true);
    }
  };

  return (
    <Link
      {...props}
      href={href}
      onClick={layout_options?.current === LAYOUTS.TYPE_MAP ? handleClick : filterSearch}
      ariaLabelledBy={name}
    >
      {children}
    </Link>
  );
};

FilterLink.propTypes = {
  ...linkPropTypes,
  filterId: string,
};

export default FilterLink;
