const { VISIBLE } = require('../../../constants');

const hasChangeLayout = (layoutOptions) => {
  const { state, views = [] } = layoutOptions || {};
  const isVisible = state === VISIBLE;
  const hasMultipleViews = views.length > 1;

  return isVisible && hasMultipleViews;
};

module.exports = {
  hasChangeLayout,
};
