import React, { useContext } from 'react';
import { arrayOf, func, element, oneOfType, string, shape, number } from 'prop-types';

import classnames from 'classnames';

import { StaticPropsContext } from '../../context/static-props';
import OneColumnItemPolycard from './one-column-item-polycard';
import GridLayout from '../grid-rows';
import WrappedContext from '../../wrapped-context';
import { LayoutFadeContext } from '../../context/layout-fade';

const namespace = 'ui-search-layout';

const GridColumn = ({ itemComponent, layout, polycard_context, results = null, cols }) => {
  const { deviceType, country } = useContext(StaticPropsContext);
  const LayoutItem = polycard_context ? OneColumnItemPolycard : itemComponent;

  const { isFade } = useContext(LayoutFadeContext);

  return (
    <WrappedContext contextValue={polycard_context} layout={layout} deviceType={deviceType} countryConfig={country}>
      <ol
        className={classnames(
          `${namespace}`,
          { [`${namespace}--${layout}`]: layout },
          { [`${namespace}--fade`]: isFade },
        )}
        data-cols={cols}
      >
        <GridLayout itemComponent={LayoutItem} layout={layout} results={results} cols={cols} deviceType={deviceType} />
      </ol>
    </WrappedContext>
  );
};

GridColumn.propTypes = {
  cols: number,
  itemComponent: oneOfType([element, func]).isRequired,
  layout: string.isRequired,
  polycard_context: shape({}),
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

export default GridColumn;
