import React, { useContext, useState, useEffect } from 'react';
import { shape, bool } from 'prop-types';

import classnames from 'classnames';
import Tag from '@andes/tag';
import { Pill } from '@andes/badge';

import IconClose from '../icons/close';
import { SuggestContext } from '../context/suggest-cpg';
import useSuggestions from './use-suggestions';
import { handleClickTrackEvents } from '../../lib/handleClickTrackEvents';
import { distributeInColumns } from '../../lib/misc';

const MAX_TAGS_COLUMNS = 2;
const MAX_TAGS_PER_COLUMN = 3;

const namespace = 'ui-search-suggestions';

const renderTagsColumn = (col, number) => (
  <div key={number} className={`${namespace}__list__column ${namespace}__list__column--${number}`}>
    {col.map((tag, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <a key={index} className={`${namespace}__link`} onClick={handleClickTrackEvents(tag)} href={tag.url}>
        <span className="sr-only">{}</span>
        <Tag label={tag.name} disabled className={`${namespace}__tag`} />
      </a>
    ))}
  </div>
);

const renderTagsColumns = (columns) => columns.map((col, index) => renderTagsColumn(col, index + 1));

const renderTags = (tags) => renderTagsColumns(distributeInColumns(tags, MAX_TAGS_COLUMNS, MAX_TAGS_PER_COLUMN));

const Suggestions = ({ open, suggestions }) => {
  const { setOpenSuggestions } = useContext(SuggestContext);
  const [animatedOpen, setAnimated] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();

    if (animatedOpen) {
      setAnimated(false);
      setTimeout(() => setOpenSuggestions(false), 250);
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setAnimated(true);
      }, 250);
    }
  }, [open, suggestions]);

  return (
    open && (
      <div className={classnames(`${namespace}__wrapper`, { [`${namespace}__wrapper--animated-open`]: animatedOpen })}>
        <div className={classnames(namespace)}>
          <div className={`${namespace}__header`}>
            <Pill className={`${namespace}__header__icon`} color="green" size="small" contentType="icon" />
            <h3 className={`${namespace}__header__title`}>{suggestions.title.text}</h3>
            <button
              type="button"
              className={`${namespace}__close`}
              onClick={handleClose}
              tabIndex={0}
              aria-label="Cerrar"
            >
              <span className="sr-only">{}</span>
              <IconClose />
            </button>
          </div>
          <h3 className={`${namespace}__subtitle`}>{suggestions.subtitle.text}</h3>
          <ul className={`${namespace}__list`}>{renderTags(suggestions.tags)}</ul>
        </div>
      </div>
    )
  );
};

Suggestions.propTypes = {
  open: bool,
  suggestions: shape({}),
};

Suggestions.defaultProps = {
  open: false,
  suggestions: null,
};

const SuggestionsContainer = () => {
  const { openSuggestions, suggestions } = useSuggestions();

  return openSuggestions ? <Suggestions open={openSuggestions} suggestions={suggestions} /> : null;
};

export default SuggestionsContainer;
export { Suggestions };
