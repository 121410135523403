import React from 'react';
import PropTypes from 'prop-types';

import ExtendedMenu from '@seo-frontend-components/extended-menu';
import ExtendedMenuMobile from '@seo-frontend-components/extended-menu-mobile';

import { useStaticProps } from '../../context/static-props';

const SeoExtendedMenu = ({ extendedMenuInterventionObject }) => {
  const { title, menu, see_more: seeMore, see_less: seeLess, limit } = extendedMenuInterventionObject;
  const { deviceType } = useStaticProps();
  const isMobile = deviceType === 'mobile';
  return (
    <div className="ui-search-seo-extended-menu__container">
      {isMobile ? (
        <ExtendedMenuMobile title={title} menu={menu} />
      ) : (
        <ExtendedMenu title={title} menu={menu} seeMore={seeMore} seeLess={seeLess} limit={limit} />
      )}
    </div>
  );
};

SeoExtendedMenu.propTypes = {
  extendedMenuInterventionObject: PropTypes.shape({
    title: PropTypes.string.isRequired,
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
    see_more: PropTypes.string.isRequired,
    see_less: PropTypes.string.isRequired,
  }).isRequired,
};

export default SeoExtendedMenu;
