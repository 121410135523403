/* eslint-disable import/order */
import React, { useContext, useState, useEffect } from 'react';

import { string, shape } from 'prop-types';
import get from 'lodash/get';
import useResults from './use-results';
import { StaticPropsContext } from '../context/static-props';
import { LAYOUTS, VISUAL_IDS } from '../../constants';

// Layout item
import DesktopLayoutItem from './renderer/layout-item/desktop';

import DesktopLayoutItemPI from './renderer/layout-item/desktop.pi';
import MapLayoutItem from './renderer/layout-item/map';
import OneColumnLayout from './columns/one-column';
import GridColumn from './columns/grid-column';
import MapLayout from './columns/map';

const getLayoutByName = (name) => {
  // References
  switch (name) {
    case LAYOUTS.TYPE_GRID:
      return GridColumn;
    case LAYOUTS.TYPE_MAP:
      return MapLayout;

    default:
      return OneColumnLayout;
  }
};

const LayoutItems = {
  desktop: {
    [VISUAL_IDS.DEFAULT]: DesktopLayoutItem,
    [VISUAL_IDS.PI]: DesktopLayoutItemPI,
  },
  tablet: {
    [VISUAL_IDS.DEFAULT]: DesktopLayoutItem,
    [VISUAL_IDS.PI]: DesktopLayoutItemPI,
  },
};

const getLayoutItem = (layoutName, deviceType, visualId) => {
  if (layoutName === LAYOUTS.TYPE_MAP) {
    return MapLayoutItem;
  }

  return get(LayoutItems, `${deviceType}.${visualId || VISUAL_IDS.DEFAULT}`);
};
const LayoutManager = ({ deviceType = '', name, layout = '', polycard_context = null, ...rest }) => {
  const resultLength = deviceType === 'tablet' ? 2 : 3;
  const [useItemsPerRow, setUseItemsPerRow] = useState(resultLength);
  const { visualId } = useContext(StaticPropsContext);
  const LayoutComponent = layout || getLayoutByName(name);
  const LayoutItemComponent = getLayoutItem(name, deviceType, visualId);

  useEffect(() => {
    const itemsPerRow = window.innerWidth < 1024 ? 2 : 3;

    setUseItemsPerRow(itemsPerRow);
  }, []);

  return (
    <LayoutComponent
      layout={name}
      itemComponent={LayoutItemComponent}
      polycard_context={polycard_context}
      deviceType={deviceType}
      cols={useItemsPerRow}
      {...rest}
    />
  );
};

LayoutManager.propTypes = {
  deviceType: string,
  layout: string,
  name: string.isRequired,
  polycard_context: shape({}),
};

const LayoutManagerContainer = () => {
  const { currentLayout, results, polycard_context, layout_options } = useResults();
  const current = currentLayout === undefined ? layout_options.current : currentLayout.id;
  const { deviceType } = useContext(StaticPropsContext);

  return <LayoutManager name={current} results={results} polycard_context={polycard_context} deviceType={deviceType} />;
};

export default LayoutManagerContainer;
export { LayoutManager };
