import { useContext } from 'react';

import { EshopsContext } from '../context/eshop-environment';

const useEshopsEnvironment = () => {
  const { isEshopsEnvironment, setEshopsEnvironment } = useContext(EshopsContext);

  return { isEshopsEnvironment, setEshopsEnvironment };
};

export default useEshopsEnvironment;
