import React from 'react';
import { arrayOf, shape, string, number, oneOfType } from 'prop-types';

import omit from 'lodash/omit';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { RenderPrice } from './render-price';
import StyledLabelWrapper from '../styled-label/styled-label';
import ComposedLabelValue from '../composed-label/composed-label-value';
import { GENERIC_KEY_PREFIXES } from '../layout/renderer/constants';
import { mapWithKey } from '../layout/renderer/utils';

const namespace = 'ui-search-composed-label-alternatives';

const PLACEHOLDER_FORMAT_REGEX = /\{\w+\}/gi;
const PLACEHOLDER_DELIMITERS_REGEX = /{|}/;

const getStylesFromProps = (props) => {
  const styleMap = {
    marginBottom: get(props, 'margin.bottom'),
    borderRadius: get(props, 'border_radius'),
  };
  const ALLOWED_STYLES = pick({ ...props, ...styleMap }, [
    'borderRadius',
    'marginBottom',
    'background',
    'padding',
    'color',
  ]);
  const styles = {
    ...ALLOWED_STYLES,
  };

  return styles;
};

const renderNormalText = (text, props) => {
  if (props.font_size) {
    props.size = props.font_size;
  }

  return (
    <StyledLabelWrapper as="pre" className={`${namespace}--normal-text`} text={text} {...omit(props, ['padding'])} />
  );
};

const ComposedLabelAlternative = ({ text, values, ...props }) => {
  const componentsToRender = [];

  let currentIndex = 0;
  let match = PLACEHOLDER_FORMAT_REGEX.exec(text);

  while (match !== null) {
    const matchIndex = match.index;
    const placeHolder = match[0];
    const valueKey = placeHolder.split(PLACEHOLDER_DELIMITERS_REGEX)[1];
    const normalTextToRender = matchIndex > 0 && text.substring(currentIndex, matchIndex);

    currentIndex = matchIndex + placeHolder.length;

    if (normalTextToRender) {
      componentsToRender.push(renderNormalText(normalTextToRender, omit(props, ['padding'])));
    }

    const valueProps = values?.find((value) => value.key === valueKey);

    if (valueProps) {
      switch (valueProps.type) {
        case 'price':
          componentsToRender.push(<RenderPrice className={`${namespace}__price`} {...props} {...valueProps} />);

          break;
        case 'icon':
        case 'text':

        // falls through
        default: {
          if (valueProps.font_size) {
            valueProps.size = valueProps.font_size;
          }

          componentsToRender.push(
            <ComposedLabelValue className={`${namespace}--value`} as="pre" {...props} {...valueProps} />,
          );

          break;
        }
      }
    }

    match = PLACEHOLDER_FORMAT_REGEX.exec(text);
  }

  if (currentIndex < text.length) {
    props.size = props.font_size;
    componentsToRender.push(renderNormalText(text.substring(currentIndex), omit(props, ['padding'])));
  }

  return (
    <div className={`${namespace}__container`} style={getStylesFromProps(props)}>
      <div className={`${namespace}__items`}>
        {mapWithKey(componentsToRender, GENERIC_KEY_PREFIXES.MODAL_COMPONENTS)}
      </div>
    </div>
  );
};

const STYLES_PROPS = {
  border_radius: string,
  padding: string,
  margin: shape({
    bottom: number,
    top: number,
  }),
};

ComposedLabelAlternative.propTypes = {
  ...STYLES_PROPS,
  text: string,
  values: arrayOf(
    shape({
      key: string,
      type: string,
      text: string,
      font_weight: string,
      font_size: oneOfType([string, number]),
      color: string,
    }),
  ),
};

ComposedLabelAlternative.defaultProps = {
  text: '',
  values: [],
};

export default ComposedLabelAlternative;
