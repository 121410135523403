import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { ARROW_DIRECTIONS } from '../../pagination/constants';
import { ArrowButton, Content, Node } from '../../pagination/components';

const namespace = 'ui-search-map-pagination';

const { BACK, NEXT } = ARROW_DIRECTIONS;

const Pagination = ({ currentPage, previousPage, nextPage }) => (
  <Content className={namespace}>
    <ArrowButton
      direction={BACK}
      disabled={!previousPage.show}
      title={previousPage.title}
      href={previousPage.href}
      handleChange={previousPage.onClick}
    />

    <Node isActualPage value={currentPage} />

    <ArrowButton
      direction={NEXT}
      disabled={!nextPage.show}
      title={nextPage.title}
      href={nextPage.href}
      handleChange={nextPage.onClick}
    />
  </Content>
);

const PAGE_PROPTYPES = shape({
  show: bool.isRequired,
  title: string.isRequired,
  href: string,
  onClick: func.isRequired,
}).isRequired;

Pagination.propTypes = {
  currentPage: number.isRequired,
  nextPage: PAGE_PROPTYPES,
  previousPage: PAGE_PROPTYPES,
};

export default Pagination;
