import React, { createContext, useState } from 'react';
import { node, shape } from 'prop-types';

import * as helpers from './helpers';

const FiltersContext = createContext({});
const { Consumer: FiltersConsumer, Provider } = FiltersContext;

const { isLocationFilter, removeItemLocation } = helpers;

const FiltersProvider = ({ children, history }) => {
  const [hideModal, setHideModal] = useState(null);
  const [lastFilterId, setLastFilterId] = useState('');
  const [isSecondaryFilterSelected, setSecondaryFilterSelected] = useState(false);

  const filterSearch = (href, id, hash) => (event) => {
    if (event) {
      event.preventDefault();
    }

    const { pathname } = new URL(href, window.location);

    history.push({
      pathname: isLocationFilter(id) ? removeItemLocation(pathname) : pathname,
      search: history.location.search,
      hash: hash ? `#${hash}` : undefined,
    });

    setLastFilterId(id);

    if (hideModal) {
      hideModal();
    }
  };

  const changeSecondaryFilter = (isSecondaryFilter) => {
    setSecondaryFilterSelected(isSecondaryFilter);
  };

  return (
    <Provider
      value={{
        filterSearch,
        changeSecondaryFilter,
        isSecondaryFilterSelected,
        lastFilterId,
        setHideModal,
        setLastFilterId,
        ...helpers,
      }}
    >
      {children}
    </Provider>
  );
};

FiltersProvider.propTypes = {
  children: node.isRequired,
  history: shape().isRequired,
};

export { FiltersContext, FiltersProvider, FiltersConsumer };
