import React from 'react';
import { string, arrayOf, bool } from 'prop-types';

import classnames from 'classnames';

import IconFull from '../icons/full';
import IconMelicoin from '../icons/icon-melicoin';
import IconVirtualTour from '../icons/virtual-tour';
import IconMelichoice from '../icons/melichoice';
import IconInternationalCBT from '../icons/international-cbt';
import IconShippingTruck from '../icons/shipping-truck';
import MeliPlusIcon from '../icons/meli-plus';
import IconInternationalFilterMobile from '../icons/international-filter-cbt-mobile';
import IconCockade from '../icons/cockade';
import IconGoodFinancig from '../icons/good_financing';
import IconSamedayTruck from '../icons/sameday_truck';
import IconInstallation from '../icons/installations_icon';
import IconBigBrands from '../icons/big-brands';
import IconPremiumBrands from '../icons/premium-brands';

const namespace = 'ui-search-icon-label';

const ICON_FORMAT_REGEX = /\{icon\}/gi;

const renderIcon = (type, srLabel) => {
  switch (type) {
    case 'fulfillment':
      return <IconFull />;
    case 'melicoin':
      return <IconMelicoin />;
    case 'virtual_tour_icon':
      return <IconVirtualTour />;
    case 'cbt_international':
    case 'cbt_international_desktop':
      return <IconInternationalCBT srLabel={srLabel} />;
    case 'cbt_international_mobile':
      return <IconInternationalFilterMobile />;
    case 'meli_plus_icon':
      return <MeliPlusIcon />;
    case 'melichoice_icon':
      return <IconMelichoice />;
    case 'truck':
    case 'free':
      return <IconShippingTruck />;
    case 'cockade':
      return <IconCockade />;
    case 'good_financing':
      return <IconGoodFinancig />;
    case 'sameday':
    case 'sameday_turbo':
    case 'nexday':
      return <IconSamedayTruck />;
    case 'installations_icon':
      return <IconInstallation />;
    case 'is_highlight_brand_premium':
      return <IconPremiumBrands />;
    case 'is_highlight_brand':
      return <IconBigBrands />;

    default:
      return null;
  }
};

const IconLabel = ({
  className = '',
  text,
  icons = [],
  htmlForIDValue = '',
  hasHtml = false,
  srLabel,
  Tag = 'label',
}) => {
  const labelClassName = classnames(className, namespace);

  if (hasHtml) {
    // eslint-disable-next-line react/no-danger
    return <span htmlFor={htmlForIDValue} className={labelClassName} dangerouslySetInnerHTML={{ __html: text }} />;
  }

  const iconsMatches = text?.match(ICON_FORMAT_REGEX);

  if (!icons || !iconsMatches || iconsMatches.length < icons.length) {
    return (
      <Tag htmlFor={htmlForIDValue} className={labelClassName}>
        {text}
      </Tag>
    );
  }

  const textParts = text.split(ICON_FORMAT_REGEX);

  return (
    <Tag htmlFor={htmlForIDValue} className={labelClassName} key={htmlForIDValue}>
      {textParts.map((textPart, index) => (
        <span key={textPart}>
          {textPart}
          {index < icons.length && renderIcon(icons[index], srLabel)}
        </span>
      ))}
    </Tag>
  );
};

IconLabel.propTypes = {
  className: string,
  hasHtml: bool,
  htmlForIDValue: string,
  icons: arrayOf(string),
  Tag: string,
  text: string.isRequired,
  srLabel: string,
};

export default IconLabel;
