import React, { useState, useCallback } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';

import FacetedSearchDesktop from 'vis-faceted-search/lib/Desktop';
import { buildInitialState, deserializeState } from 'vis-faceted-search/helpers';

import PrimaryFilters from './primary-filters/primary-filters';
import SecondaryFilters from './secondary-filters/secondary-filters';
import { useFilters, useSearch } from '../../../../hooks/context';
import useVisFacetedSearch from '../../../search/hooks/use-vis-faceted-search';
import { NAMESPACE } from './constants';
import { VISIBLE, FILTERS, LAYOUTS } from '../../../../constants';
import { sendTrack } from '../../../search/helpers/track';
import useMapConfig from '../../hooks/use-map-config';
import { trackClickEvent } from '../../track';

const { STATE, OPERATION_SUBTYPE: OPST } = FILTERS.IDS;

const Filters = ({ isLoading, filters, newFacetedSearch, onPrimaryFilterChange }) => {
  const { setLastFilterId, isSecondaryFilterSelected, changeSecondaryFilter } = useFilters();
  const isFacetedSearchVisible = newFacetedSearch?.state === VISIBLE;
  const appliedFilters = newFacetedSearch?.applied_filters || filters.applied_filters.filters;
  const facetedConfig = newFacetedSearch?.faceted_config?.data ?? null;
  const { renderConfig, initialState } = useVisFacetedSearch();
  const { layout_options } = useSearch();
  const [facetedState, setFacetedState] = useState(() => buildInitialState(renderConfig, initialState));
  const {
    tracks: { search_tracks },
  } = newFacetedSearch;
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const onChange = useCallback(
    (internalState) => {
      setFacetedState(internalState);
    },
    [setFacetedState],
  );

  const handleChange = ({ OPERATION, category, LOCATION, OPERATION_SUBTYPE }) => {
    if (setLastFilterId && LOCATION !== '&!{}') {
      setLastFilterId(STATE);
    }

    if (layout_options?.current === LAYOUTS.TYPE_MAP && !isSecondaryFilterSelected) {
      onChange({ OPERATION, category, LOCATION, OPERATION_SUBTYPE });
      sendTrack(facetedState, search_tracks, true);
      trackClickEvent(null, track.melidata);
    }

    if (isSecondaryFilterSelected) {
      changeSecondaryFilter(false);
    }

    const locationParsed = deserializeState({ LOCATION });

    return onPrimaryFilterChange({
      OPERATION,
      OPERATION_SUBTYPE: OPERATION_SUBTYPE ? facetedConfig.find((config) => config.id === OPST)?.values.true : null,
      PROPERTY_TYPE: category ? category.split('_')[1] : null,
      city: locationParsed?.city,
      state: locationParsed?.state,
      neighborhood: locationParsed?.neighborhood,
    });
  };

  return (
    <div className={NAMESPACE}>
      {facetedConfig ? (
        <FacetedSearchDesktop
          initialState={initialState}
          renderConfig={renderConfig}
          onChange={!isLoading && handleChange}
        />
      ) : (
        isFacetedSearchVisible && (
          <PrimaryFilters
            appliedFilters={appliedFilters}
            availableFilters={filters.available_filters.filters}
            facetedFilters={newFacetedSearch}
            isLoading={isLoading}
            onChange={onPrimaryFilterChange}
          />
        )
      )}
      <SecondaryFilters
        availableFilters={filters.available_filters.filters}
        availableLabels={filters.available_filters.labels}
        appliedFilters={filters.applied_filters.filters}
        appliedLabels={filters.applied_filters.labels}
        isLoading={isLoading}
        {...filters.applied_text}
      />
    </div>
  );
};

Filters.propTypes = {
  filters: shape({
    applied_filters: arrayOf(shape({})).isRequired,
    applied_labels: shape({}).isRequired,
    available_filters: arrayOf(shape({})).isRequired,
    available_labels: shape({}).isRequired,
  }),
  isLoading: bool,
  newFacetedSearch: shape({
    applied_filters: arrayOf(shape({})),
    faceted_config: arrayOf(shape({})),
  }),
  onPrimaryFilterChange: func.isRequired,
};

Filters.defaultProps = {
  filters: {},
  isLoading: false,
};

export default Filters;
