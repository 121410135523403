import { useContext } from 'react';

import { useSearch } from '../../hooks/context';
import { StaticPropsContext } from '../context/static-props';

const useBillboard = () => {
  const { lowEnd, billboardState } = useContext(StaticPropsContext);
  const { billboard, bookmark } = useSearch();

  return { billboard, lowEnd, billboardState, bookmark };
};

export default useBillboard;
