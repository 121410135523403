import React, { useReducer } from 'react';
import { node } from 'prop-types';

const FreeShippingThContext = React.createContext('');
const { Provider, Consumer: FreeShippingThConsumer } = FreeShippingThContext;

const FreeShippingThProvider = ({ children, value }) => {
  const initialState = {
    superChange: false,
    firstLoadSuper: true,
    thresholdSuperLabel: '',
    freeShippingSuper: 'paid_shipping',
    partnerChange: false,
    firstLoadPartner: true,
    thresholdPartnerLabel: '',
    freeShippingPartner: 'paid_shipping',
    superShippingPromiseLabel: '',
    partnerShippingPromiseLabel: '',
  };

  function reducer(state, action) {
    const thresholdLabeldata = action?.payload?.threshold_labels;
    const thresholdLabelState = action?.payload?.threshold_label_state;

    switch (action?.type) {
      case 'FIRST_LOAD_SUPER':
        return {
          ...state,
          firstLoadSuper: false,
          freeShippingSuper: thresholdLabelState,
        };
      case 'FIRST_LOAD_PARTNER':
        return {
          ...state,
          firstLoadPartner: false,
          freeShippingPartner: thresholdLabelState,
        };
      case 'CHANGE_THRESHOLD_SUPER_TEXT':
        if (state.freeShippingSuper === thresholdLabelState && !state.superChange) {
          return {
            ...state,
          };
        }

        return {
          ...state,
          superChange: true,
          thresholdSuperLabel: thresholdLabeldata,
        };
      case 'CHANGE_THRESHOLD_PARTNER_TEXT':
        if (state.freeShippingPartner === thresholdLabelState && !state.partnerChange) {
          return {
            ...state,
          };
        }

        return {
          ...state,
          partnerChange: true,
          thresholdPartnerLabel: thresholdLabeldata,
        };
      case 'CHANGE_THRESHOLD_PROMISE_SUPER_TEXT':
        return {
          ...state,
          superShippingPromiseLabel: thresholdLabeldata,
        };
      case 'CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT':
        return {
          ...state,
          partnerShippingPromiseLabel: thresholdLabeldata,
        };

      default:
        return state;
    }
  }

  const [thresholdState, thresholdDispatch] = useReducer(reducer, value || initialState);

  return <Provider value={{ thresholdState, thresholdDispatch }}>{children}</Provider>;
};

FreeShippingThProvider.propTypes = {
  children: node.isRequired,
};

export { FreeShippingThContext, FreeShippingThProvider, FreeShippingThConsumer };
