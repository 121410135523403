import React, { useState, useEffect, useRef } from 'react';
import { oneOf } from 'prop-types';

import classnames from 'classnames';
import Snackbar from '@andes/snackbar';

import FullSnackbar from './full-shipping-info';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import useFullFreeshipping from './use-full-freeshipping';

const namespace = 'ui-search-fullpopup';

const FullShipping = ({ location }) => {
  const [visible, setVisible] = useState(true);
  const refCruce = useRef();
  const [isOnScreen] = useIntersectionObserver(refCruce, { threshold: 0.2 }, 0.1, true);
  const { currentFullFreeshipping, setFullFreeShippingVisibility } = useFullFreeshipping();

  useEffect(() => {
    setVisible(true);
    setFullFreeShippingVisibility(true);

    const header = document.querySelector('.nav-header');

    if (header) {
      header.style.zIndex = 10;
    }

    const timer = setTimeout(() => {
      setVisible(false);
      setFullFreeShippingVisibility(false);

      const headerIn = document.querySelector('.nav-header');

      if (headerIn) {
        headerIn.style = null;
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [setFullFreeShippingVisibility, currentFullFreeshipping]);

  return (
    <>
      <div ref={refCruce} />
      <div
        className={classnames({
          'ui-search-fullpopup-up-float': isOnScreen,
          'ui-search-fullpopup-up-sticky': !isOnScreen,
        })}
      >
        <Snackbar
          show={visible}
          className={classnames(namespace, `${namespace}-${location}`, {
            [`${namespace}-${location}--animate-show`]: true,
            [`${namespace}-${location}--animate-hide`]: !visible,
          })}
        >
          <FullSnackbar />
        </Snackbar>
      </div>
    </>
  );
};

FullShipping.propTypes = {
  location: oneOf(['up', 'down']),
};

FullShipping.defaultProps = {
  location: 'up',
};

export default FullShipping;
