/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';

import { isLocalStorageSupported } from '../lib/dom-utils';

const useTooltip = (id) => {
  const localStorageSupported = isLocalStorageSupported();

  const initialState = { wasClosed: false, views: 0 };
  const [tooltipStatus, setTooltipStatus] = useState(initialState);

  function handleClose() {
    const newStatus = { wasClosed: true, views: tooltipStatus.views };

    if (localStorageSupported) {
      localStorage.setItem(id, JSON.stringify(newStatus));
    }

    setTooltipStatus(newStatus);
  }

  useEffect(() => {
    if (localStorageSupported) {
      const localStorageStatusStr = localStorage.getItem(id);
      const localStorageStatus = localStorageStatusStr ? JSON.parse(localStorageStatusStr) : initialState;

      localStorageStatus.views += 1;

      try {
        localStorage.setItem(id, JSON.stringify(localStorageStatus));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving in localStorage');
      }

      setTooltipStatus(localStorageStatus);
    }
  }, [localStorageSupported]);

  return [tooltipStatus, handleClose];
};

export default useTooltip;
