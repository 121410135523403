import { useSearch } from '../../hooks/context';
import { serialize } from './helpers';

const usePagination = () => {
  const { pagination } = useSearch();

  return pagination ? serialize(pagination) : null;
};

export default usePagination;
