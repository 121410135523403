import React, { useEffect, useState } from 'react';
import { bool, func, number, shape } from 'prop-types';

import classnames from 'classnames';
import AndesTooltip from '@andes/tooltip';

import useTooltip from '../../../../hooks/use-tooltip';
import Actions from '../../../rescue/components/actions';
import { getFilterTooltipKey } from './helpers';
import { MOBILE_DEFAULT_PROPS, MOBILE_PROP_TYPES } from './constants';
import { trackEvent } from '../../../../lib/melidata';

const namespace = 'ui-search-tooltip-filter';

const FilterTooltip = ({
  className,
  children,
  mask,
  maskClosable,
  onClose,
  tooltip: { title, offset_x, offset_y, content, filter_content_id, max_print, actions, side, tracks },
  tooltipStatus: { wasClosed, views } = {},
}) => {
  const [visible, setVisible] = useState(false);
  const isNoLongerVisible = wasClosed || views > max_print || !filter_content_id;

  const handleCloseTooltip = () => {
    setVisible(false);

    if (tracks?.close) {
      trackEvent({ clean: true, ...tracks.close });
    }

    onClose();
  };

  useEffect(() => {
    setVisible(true);

    if (tracks?.show) {
      trackEvent({ clean: true, ...tracks.show });
    }
  }, [tracks]);

  return (
    <AndesTooltip
      actions={<Actions actions={actions} actionButton={handleCloseTooltip} />}
      className={classnames(namespace, className)}
      closable
      onClose={handleCloseTooltip}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      mask={mask}
      maskClosable={maskClosable}
      side={side}
      type="highlight"
      trigger={isNoLongerVisible ? 'click' : 'no-trigger'}
      visible={!isNoLongerVisible}
    >
      <div className={classnames({ 'andes-tooltip__trigger--visible': visible })}>{children}</div>
    </AndesTooltip>
  );
};

const FilterTooltipContainer = ({ tooltip, ...props }) => {
  const localStorageKey = getFilterTooltipKey(tooltip);
  const [tooltipStatus, handleClose] = useTooltip(localStorageKey);
  const wasTooltipClosed = tooltipStatus?.wasClosed ?? false;
  return (
    !wasTooltipClosed && (
      <FilterTooltip {...props} tooltip={tooltip} tooltipStatus={tooltipStatus} onClose={handleClose} />
    )
  );
};

FilterTooltip.propTypes = {
  ...MOBILE_PROP_TYPES,
  tooltipStatus: shape({
    wasClosed: bool.isRequired,
    views: number.isRequired,
  }).isRequired,
  onClose: func.isRequired,
};
FilterTooltip.defaultProps = MOBILE_DEFAULT_PROPS;

FilterTooltipContainer.propTypes = MOBILE_PROP_TYPES;
FilterTooltipContainer.defaultProps = MOBILE_DEFAULT_PROPS;

export default FilterTooltipContainer;
export { FilterTooltip };
