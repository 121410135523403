const analytics = require('./analytics');
const { trackEvent } = require('./melidata');

function handleClickTrackEvents(action) {
  return (event) => {
    event.preventDefault();

    const { href } = event.currentTarget;

    if (action.tracks) {
      if (action.tracks.analytics_track) {
        analytics.trackEvent(action.tracks.analytics_track);
      }

      trackEvent(action.tracks.melidata_track);
    }

    window.location.href = href;
  };
}

module.exports = {
  handleClickTrackEvents,
};
