import { bool, func, node, string, shape } from 'prop-types';

const BLACKLISTED_PARAMS = [
  'redirectedFromOfficialStoreZrp',
  'redirectedFromDealZrp',
  'redirectedFromCpgZrp',
  'redirectedFromEshopZrp',
];

const linkPropTypes = {
  ariaLabel: string,
  children: node.isRequired,
  className: string,
  href: string,
  isInternal: bool,
  keepLayout: bool,
  role: string,
  title: string,
  linkTag: string,
  target: string,
  tracks: shape({
    analyticsTrack: shape(),
    melidataTrack: shape(),
  }),

  onClick: func,
};

export { BLACKLISTED_PARAMS, linkPropTypes };
