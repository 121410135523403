const React = require('react');
const { oneOfType, array, object } = require('prop-types');

const ComponentList = ({ availableComponents = [], components = [] }) => {
  if (!components) {
    return null;
  }

  return components.map((component) => {
    const Component = availableComponents[component.id];

    if (!Component || component.state === 'HIDDEN') {
      return null;
    }

    return Component && <Component key={component.id} {...component} />;
  });
};

ComponentList.propTypes = {
  availableComponents: oneOfType([array, object]),
  components: array,
};

module.exports = ComponentList;
