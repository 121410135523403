import React, { useContext } from 'react';
import { oneOf } from 'prop-types';

import classnames from 'classnames';

import { FullFreeshippingContext } from '../context/full-freeshipping';

const namespace = 'ui-search-fullsnackbar-skeleton';

const Circle = () => <div className={classnames(namespace, `${namespace}__circle`)} />;

const Line = ({ size = 'large' }) => (
  <div className={classnames(namespace, `${namespace}__line`, `${namespace}__line--${size}`)} />
);

Line.propTypes = {
  size: oneOf(['small', 'large', 'extra-large']),
};

const FullShippingInfoSkeleton = () => {
  const { showShimmerAction } = useContext(FullFreeshippingContext);

  return (
    <div className={`${namespace}__header`}>
      <div className={`${namespace}__icon-line-container`}>
        <div className={`${namespace}__icon-container`}>
          <Circle />
        </div>
        <div className={`${namespace}__line-container`}>
          <Line />
          <Line size="small" />
          <Line />
        </div>
      </div>
      <div className={`${namespace}__line-button-container`}>
        {showShimmerAction && <Line size="extra-large" className={`${namespace}__line-button`} />}
      </div>
    </div>
  );
};

export default FullShippingInfoSkeleton;
