import React, { useState, useContext } from 'react';
import { arrayOf, string, shape } from 'prop-types';

import cx from 'classnames';
import { Modal as AndesModal } from '@andes/modal';
import { Switch } from '@andes/switch';

import useFilterSearch from '../../../../hooks/use-filter-search';
import { StaticPropsContext } from '../../../context/static-props';
import IconLabel from '../../../icon-label/icon-label';
import IconQuestion from '../../../icons/question';
import { LayoutContext } from '../../../context/layout';
import useMapConfig from '../../../map/hooks/use-map-config';
import { useSearch } from '../../../../hooks/context';
import { LAYOUTS } from '../../../../constants';
import { trackClickEvent } from '../../../map/track';
import HighlightedFilterContent from '../highlighted-filter/highlighted-filter-content';
import FilterTooltipContainer from '../filters-tooltip/filters-tooltip.mobile';
import { buildNewHref } from '../../../link/link-utils';
import { trackEvent } from '../../../../lib/melidata';

const namespace = 'ui-search-filter-highlighted';

const HighlightedFilterVis = ({ filterId, subtitle, switch: { is_on: isOn }, title, tooltip, url, highlight }) => {
  const { queryParams } = useContext(StaticPropsContext);
  const { currentLayout } = React.useContext(LayoutContext);
  const filterSearch = useFilterSearch(url, filterId);
  const isClientSideFilter = !!filterSearch;
  const [isChecked, setChecked] = useState(isOn);
  const { layout_options } = useSearch();
  const { searchTrack } = useMapConfig();
  const [showModal, toggleModalVisibility] = React.useState(false);
  const [showTooltip, toggleTooltipVisibility] = React.useState(true);
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const { tracks } = tooltip;

  const onClickTooltip = () => {
    if (tracks?.cta) {
      trackEvent({ clean: true, ...tracks.cta });
    }

    toggleTooltipVisibility(!showTooltip);
  };

  const handleClick = (e) => {
    if (e.type !== 'change') {
      e.preventDefault();
      setChecked(!isOn);
    }

    if (isClientSideFilter) {
      filterSearch();

      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, track.melidata);
      }

      return;
    }

    window.location.href = buildNewHref({ href: url, queryParams, isInternal: true, currentLayout });
  };

  const handleQuestionClick = (e) => {
    e.preventDefault();
    toggleModalVisibility(!showModal);
  };

  if (tooltip.actions[0]) {
    tooltip.actions[0].onClick = onClickTooltip;
  }

  const tooltipData = {
    ...tooltip,
    side: 'right',
    offset_x: 0,
    offset_y: 45,
    filter_content_id: 'APPROVED_CREDIT_LINE_TOOLTIP',
    max_print: 80,
  };

  return (
    <>
      <form action={url} onSubmit={handleClick} className={cx(`${namespace}__container`)}>
        <button name="button" type="submit" className={cx(`${namespace}__content ${namespace}__content-button`)}>
          <div className={`${namespace}__container-vertical`}>
            <div className={`${namespace}__icons-container`}>
              <IconLabel className={`${namespace}__icon-label`} {...highlight.pill} />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className={cx(`${namespace}__tooltip`)} onClick={handleQuestionClick}>
                <IconQuestion />
              </div>
            </div>
            <HighlightedFilterContent
              namespace={namespace}
              hasHtml
              subtitle={{ text: subtitle.text, className: `${namespace}__label-subtitle-fade` }}
              title={{ text: title.text, className: `${namespace}__label-title-fade` }}
              htmlForIDValue={filterId}
            />
          </div>
        </button>
        <div className={cx(`${namespace}__switch-container`)}>
          <div className={cx(`${namespace}__switch-wrapper-vis`)}>
            {showTooltip && <FilterTooltipContainer tooltip={tooltipData} side="left" />}
            <Switch
              id={filterId}
              className={`${namespace}__switch-icon ${namespace}__tooltip-trigger`}
              label=""
              checked={isChecked}
              autoComplete="off"
              onChange={handleClick}
            />
          </div>
        </div>
      </form>
      {showModal && (
        <AndesModal
          title={highlight.help_button?.modal_title}
          className={`${namespace}-modal`}
          closeButtonSrLabel="Cerrar"
          open={showModal}
          onClose={() => toggleModalVisibility(false)}
          data-testid="top-switch-filter-modal"
        >
          <iframe
            style={{ width: '100%', height: '100%' }}
            id="modalIframe"
            title="Modal Iframe"
            scrolling="no"
            src={highlight.help_button?.url}
          />
        </AndesModal>
      )}
    </>
  );
};

HighlightedFilterVis.propTypes = {
  actions: arrayOf(
    shape({
      type: string,
      label: shape({
        text: string,
      }),
    }),
  ),
  filterId: string,
  highlight: shape({
    pill: shape({
      text: string,
      icon: arrayOf(string),
    }),
    help_button: shape({
      modal_title: string,
      url: string,
    }),
  }),
  subtitle: shape({
    text: string,
    icon: arrayOf(string),
  }),
  switch: shape({}),
  title: shape({
    text: string,
    icon: arrayOf(string),
  }),
  tooltip: shape({
    title: string,
    content: string,
    url: string,
  }),
  url: string,
};

export default HighlightedFilterVis;
