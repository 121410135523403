import React, { useContext, useMemo } from 'react';
import { arrayOf, element, string, shape } from 'prop-types';

import classnames from 'classnames';

import { StaticPropsContext } from '../../context/static-props';
import OneColumnItemPolycard from './one-column-item-polycard';
import { LayoutFadeContext } from '../../context/layout-fade';
import { renderOneColumnComponents } from '../renderer/utils/render-results';
import WrappedContext from '../../wrapped-context';
import { useSearch } from '../../../hooks/context';

const namespace = 'ui-search-layout';
const shopsNamespace = 'shops__layout';

const OneColumnLayout = ({ itemComponent, results, polycard_context, layout }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const { lowEnd, visualId, deviceType, country } = useContext(StaticPropsContext);
  const { isFade } = useContext(LayoutFadeContext);
  const options = useMemo(() => ({ lowEnd, verboseLabels: true, cardSupportsLinks: true }), [lowEnd]);

  const LayoutItem = polycard_context ? OneColumnItemPolycard : itemComponent;
  const isPolycard = !!polycard_context;

  return (
    <WrappedContext contextValue={polycard_context} layout={layout} deviceType={deviceType} countryConfig={country}>
      <ol
        className={classnames(
          namespace,
          { [`${namespace}--${layout}`]: layout },
          { [`${namespace}--${visualId}`]: visualId },
          { [`${namespace}--fade`]: isFade },
          { [shopsNamespace]: isShops },
          'shops__layout',
        )}
      >
        {results.map((item, i) =>
          renderOneColumnComponents(item, i, {
            LayoutItem,
            deviceType,
            options,
            namespace,
            layout,
            isPolycard,
          }),
        )}
      </ol>
    </WrappedContext>
  );
};

// @TODO improve results shape definition
OneColumnLayout.propTypes = {
  itemComponent: element.isRequired,
  layout: string.isRequired,
  polycard_context: shape({}),
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

OneColumnLayout.defaultProps = {
  results: [],
  polycard_context: null,
};

export default OneColumnLayout;
