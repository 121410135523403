import React from 'react';

import Link from '../../../../../../../link/filter-link';
import { valueWithLinkConfigPropTypes } from '../../../../propTypes';

const ValueLinkWithIcon = ({ namespace, Icon, modifier, results, url, linkConfig, name, accessibilityText }) => {
  const ariaLabel = accessibilityText ?? name;

  return (
    <Link className={`${namespace}-icon`} href={url} {...linkConfig} ariaLabel={ariaLabel} title={ariaLabel}>
      <Icon className={`${namespace}-icon--${modifier}`} />
      {results && <span className={`${namespace}-results-qty`}>{results}</span>}
    </Link>
  );
};

ValueLinkWithIcon.propTypes = {
  ...valueWithLinkConfigPropTypes,
};

export default ValueLinkWithIcon;
