import React from 'react';
import { arrayOf, bool, node } from 'prop-types';

import has from 'lodash/has';
import classNames from 'classnames';

import ItemBrandDiscoverability from '../../../card/brand';
import ItemTitle from '../../../card/title';
import ItemPrice from '../../../card/price';
import ItemAttributes from '../../../card/attributes/attributes.desktop';
import ItemOfficialStore from '../../../card/official-store/official-store-label';
import ItemInstallments from '../../../card/installments/installments';
import PriceDetails from '../../../card/price-details/price-details';
import ItemVerticalHighlight from '../../../card/vertical-highlight/vertical-highlight';
import ItemValuePropositions from '../../../card/value-proposition/value-propositions';
import Link from '../../../link';
import Group from '../../../card/group/group';
import { mapWithKey } from '../utils';
import AddToCart from '../../../card/add-to-cart';
import PillsBuilder from '../../../pills-builder';
import {
  CARD_DATA_GROUPS,
  namespace,
  GENERIC_KEY_PREFIXES,
  VERTICAL_TYPE_MOT,
  VERTICAL_TYPE_RES,
  VERTICAL_TYPE_CPG,
  GRID,
  GALLERY,
} from '../constants';
import HightlightLabel from '../../../card/highlight/highlight-label';
import { useSearch } from '../../../../hooks/context';

const isLayoutGallery = (layout) => layout === GRID || layout === GALLERY;

const renderAddToCartComponent = (item) => {
  if (item.cpg) {
    return (
      <Group noSeparator name={CARD_DATA_GROUPS.ADD_TO_CART}>
        <AddToCart
          {...item.cpg}
          isCpg={item.vertical === VERTICAL_TYPE_CPG}
          itemId={item.id}
          productId={item?.product?.id}
          category={item.category_id}
          availableQuantity={item.available_quantity}
          minimumQuantity={item.minimum_quantity}
          labelMinQuantity={item.label_min_quantity}
          inventoryId={item.inventory_id}
          label={item.title}
          groupBy={item.group_by}
          threshold={item.threshold}
          isAd={item.is_ad}
          permalink={item.permalink}
          viewMode={item.cpg.viewMode}
        />
      </Group>
    );
  }

  return null;
};
const renderLinkTitle = (permalink, title, titleContent, target, isAd) => (
  <Link
    href={permalink}
    title={title}
    isInternal={false}
    target={target}
    isAd={isAd}
    className="ui-search-link__title-card"
  >
    {titleContent}
  </Link>
);

const handleClick = (target) => {
  window.location = target;
};

const renderPriceWithLink = (item) => (
  /* The <div> element does not require keyboard interactions, it is already in the item title */
  /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  <div onClick={() => handleClick(item.permalink)}>
    <ItemPrice
      {...item.price}
      discountSource={item.discount_source}
      from={item.from}
      showOriginalValue={!!item.price.original_price}
    />
  </div>
);

const renderPriceWithoutLink = (item) => (
  <div className="ui-search-price__part-without-link">
    <ItemPrice
      {...item.price}
      discountSource={item.discount_source}
      from={item.from}
      showOriginalValue={!!item.price.original_price}
    />
  </div>
);

const renderPrice = (item, options) => {
  const cardSupportsLinks = !!options?.cardSupportsLinks;

  if (!has(item, 'price.amount')) {
    return has(item, 'price.label') ? <div className="ui-search-price__part">{item.price.label.text}</div> : null;
  }

  return cardSupportsLinks && item.tags !== null && item.tags.includes('product_item')
    ? renderPriceWithLink(item)
    : renderPriceWithoutLink(item);
};

const renderBrand = (brand) => {
  const brandContent = <ItemBrandDiscoverability brand={brand} />;

  return brandContent;
};

const renderTitle = (title, layout, permalink, target, isAd, compats) => {
  const titleContent = <ItemTitle title={title} target={target} compats={compats} layout={layout} />;

  switch (layout) {
    default:
      return renderLinkTitle(permalink, title, titleContent, target, isAd);
  }
};

const renderHighlightGroup = ({ highlight, layout }) =>
  highlight && (
    <Group
      noSeparator
      className={classNames(`${namespace}__highlight-container`, {
        [`${namespace}__highlight-container-grid`]: isLayoutGallery(layout),
      })}
    >
      <HightlightLabel {...highlight} />
    </Group>
  );

const renderMediaTagGroup = ({ media, layout, highlighted }) =>
  isLayoutGallery(layout)
    ? (media || highlighted) && (
        <div
          className={classNames('ui-search-item__media-tag-container-grid', {
            'flex-end': !media,
          })}
        >
          {media && <HightlightLabel {...media} />}
          {highlighted && <div className="ui-search-item__highlighted-label">{highlighted}</div>}
        </div>
      )
    : media && (
        <Group noSeparator name={CARD_DATA_GROUPS.MEDIA_TAG}>
          <HightlightLabel {...media} />
        </Group>
      );

const truncateString = (str, n) => (str.length > n ? `${str.slice(0, n - 1)}...` : str);

const renderOfficialStore = (vertical, official_store, options, isAd, layout) => {
  if (!official_store) {
    return null;
  }

  const props = {
    label: official_store.text,
    permalink: official_store.permalink,
    shouldRenderLinks: !!options?.cardSupportsLinks,
    isAd: isAd || false,
    layout,
    has_official_store_icon: official_store.has_official_store_icon,
  };

  const isVerticalVIS = vertical === VERTICAL_TYPE_MOT || vertical === VERTICAL_TYPE_RES;

  if (isVerticalVIS || !!options?.verboseLabels) {
    props.label = official_store.verbose_text;
  }

  return isLayoutGallery(layout) ? (
    <div className="ui-search-item__official-store-grid">
      <ItemOfficialStore {...props} />
    </div>
  ) : (
    <ItemOfficialStore {...props} />
  );
};

const renderPriceGroup = (item, options, layout) => (
  <Group noSeparator name={CARD_DATA_GROUPS.PRICE} className="ui-search-item__group--price-grid-container">
    {isLayoutGallery(layout) ? (
      <div className="ui-search-item__group--price-grid">{item.price && renderPrice(item, options)}</div>
    ) : (
      item.price && renderPrice(item, options)
    )}
    {item.price_details && <PriceDetails priceDetails={item.price_details} />}
    {item.installments && <ItemInstallments installments={item.installments} price={item.price} />}
  </Group>
);

const renderAttributesGroup = (item, layout) => {
  const itemDescription = item.descriptions ?? item.verticalInfo?.descriptions;

  if (isLayoutGallery(layout)) {
    return (
      <div className="ui-search-item__attributes-container-grid">
        <ItemAttributes attributes={itemDescription} className="ui-search-item__attributes-grid" />
      </div>
    );
  }

  return (
    <Group noSeparator name={CARD_DATA_GROUPS.ATTRIBUTES}>
      <ItemAttributes attributes={itemDescription} />
    </Group>
  );
};

const renderGoodPrice = (item) => {
  const itemGoodPrice = item.good_price ?? item.verticalInfo?.good_price;

  return (
    itemGoodPrice && (
      <Group noSeparator name={CARD_DATA_GROUPS.ATTRIBUTES} className="ui-search-item__group--good-price">
        <HightlightLabel {...itemGoodPrice} />
      </Group>
    )
  );
};

const Info16 = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0166 9.17217L6.01514 9.17217L6.01514 7.97217L10.0166 7.97217L10.0166 9.17217Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0166 11.5403L6.01514 11.5403L6.01514 10.3403L10.0166 10.3403L10.0166 11.5403Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99997 0.600098V15.4001H13V3.74538L9.74238 0.600098L2.99997 0.600098ZM4.19997 14.2001V1.8001L8.59995 1.80009V5.40014H11.8V14.2001H4.19997ZM11.7434 4.20014L9.79994 2.32373V4.20014H11.7434Z"
      fill="black"
      fillOpacity="0.9"
    />
  </svg>
);

const renderPillBuilder = (item) => {
  const itemPillBuilder = item?.pills_builder;

  return (
    itemPillBuilder && (
      <div className="ui-search-item__mot-pills--builder">
        <PillsBuilder
          pills={itemPillBuilder}
          sourceIcons={{
            Info16,
          }}
        />
      </div>
    )
  );
};

const renderPillBottomBuilder = (item) => {
  const itemPillBottomBuilder = item?.pills_bottom_builder;

  return (
    itemPillBottomBuilder && (
      <div className="ui-search-item__mot-pills-bottom--builder">
        <PillsBuilder
          pills={itemPillBottomBuilder}
          sourceIcons={{
            Info16,
          }}
        />
      </div>
    )
  );
};

const renderVerticalHighlightGroup = (item) =>
  (item.vertical_highlight || (item.value_propositions && item.value_propositions.length > 0)) && (
    <Group name={CARD_DATA_GROUPS.VERTICAL_HIGHLIGHT}>
      {item.vertical_highlight && <ItemVerticalHighlight {...item.vertical_highlight} />}
      {item.value_propositions && item.value_propositions.length > 0 && (
        <ItemValuePropositions propositions={item.value_propositions} />
      )}
    </Group>
  );

const ContentSkeleton = ({
  topComponents,
  leftColComponents = null,
  rightColComponents = null,
  extraPadding = false,
  bottomComponents = null,
}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <>
      {mapWithKey(topComponents, GENERIC_KEY_PREFIXES.TOP_COLUMN)}
      {(leftColComponents || rightColComponents) && (
        <div className={classNames(`${namespace}__content-columns`, { 'shops__content-columns': isShops })}>
          {leftColComponents && (
            <div
              className={classNames(`${namespace}__content-column ${namespace}__content-column--left`, {
                'shops__content-columns-left': isShops,
              })}
            >
              {mapWithKey(leftColComponents, GENERIC_KEY_PREFIXES.LEFT_COLUMN)}
            </div>
          )}
          {rightColComponents && (
            <div
              className={classNames(`${namespace}__content-column`, `${namespace}__content-column--right`, {
                [`${namespace}__content-column--extra-padding`]: extraPadding,
                'shops__content-columns-right': isShops,
              })}
            >
              {mapWithKey(rightColComponents, GENERIC_KEY_PREFIXES.RIGHT_COLUMN)}
            </div>
          )}
        </div>
      )}
      {bottomComponents && (
        <div
          className={classNames(`${namespace}__content-column ${namespace}__content-column--bottom`, {
            'shops__content-columns-bottom': isShops,
          })}
        >
          {mapWithKey(bottomComponents, GENERIC_KEY_PREFIXES.BOTTOM_COLUMN)}
        </div>
      )}
    </>
  );
};

ContentSkeleton.propTypes = {
  bottomComponents: arrayOf(node),
  extraPadding: bool,
  leftColComponents: arrayOf(node),
  rightColComponents: arrayOf(node),
  topComponents: arrayOf(node).isRequired,
};

export {
  renderPrice,
  renderTitle,
  renderBrand,
  renderOfficialStore,
  renderPriceGroup,
  renderAttributesGroup,
  renderHighlightGroup,
  renderGoodPrice,
  renderMediaTagGroup,
  renderVerticalHighlightGroup,
  renderAddToCartComponent,
  ContentSkeleton,
  truncateString,
  isLayoutGallery,
  renderPillBuilder,
  renderPillBottomBuilder,
};
