import React from 'react';
import { func, bool, shape, string } from 'prop-types';

import classnames from 'classnames';
import Card from '@andes/card';

import { MainImage } from '../common/main-image';
import Link from '../../../../link';
import { useStaticProps } from '../../../../context/static-props';
import { namespace } from '../../constants';
import { verticalContentRenderer } from '../../../../map/vertical-content';

const InfowindowItem = ({ item, options, onClick }) => {
  const { deviceType } = useStaticProps();
  const { title, permalink, pictures } = item;

  return (
    <Card animated={false} className={classnames(namespace, `${namespace}__infowindow`)}>
      <div className={`${namespace}__wrapper`}>
        <Link
          className={`${namespace}__infowindow-image-link`}
          href={permalink}
          title={title}
          isInternal={false}
          target="_blank"
          onClick={onClick}
        >
          <MainImage pictures={pictures} item={item} options={options} />
        </Link>
        <Link
          className={`${namespace}__content-link`}
          href={permalink}
          title={title}
          isInternal={false}
          target="_blank"
          onClick={onClick}
        >
          {verticalContentRenderer(item, deviceType)}
        </Link>
      </div>
    </Card>
  );
};

InfowindowItem.propTypes = {
  item: shape({
    title: string.isRequired,
    permalink: string.isRequired,
    vertical: string.isRequired,
    pictures: shape({}),
  }).isRequired,
  options: shape({
    lowEnd: bool,
  }),
  onClick: func,
};

InfowindowItem.defaultProps = {
  options: undefined,
};

export default React.memo(InfowindowItem);
