const serialize = ({ next_page, previous_page, selected_page, pagination_nodes_url, show_pagination, page_count }) => ({
  nextPage: next_page,
  previousPage: previous_page,
  selectedPage: selected_page,
  paginationNodes: pagination_nodes_url,
  showPagination: show_pagination,
  pageCount: Number(page_count),
});

const findIndex = (paginationNodes, page) => paginationNodes.findIndex((node) => Number(node.value) === page);

const formatUrl = (paginationNodes, firstPage, lastPage) => (href, page) => {
  if (page < firstPage || page > lastPage) {
    return href;
  }

  const nodePagePosition = findIndex(paginationNodes, page);

  let url = href;

  if (paginationNodes[nodePagePosition]) {
    url = paginationNodes[nodePagePosition].url;
  } else {
    // TODO FIXME Agregar métrica Datadog
    const tempPage = paginationNodes[findIndex(paginationNodes, page - 1)];

    if (tempPage) {
      url = tempPage.url;
    }
  }

  return url;
};

export { serialize, formatUrl };
