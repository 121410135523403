import React, { useMemo } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { useFilters } from '../../../../../hooks/context';
import { NAMESPACE } from '../constants';
import { getAppliedFilters } from '../../../../context/filters/helpers';
import useFacetedFilters from '../../../../../hooks/use-faceted-filters';
import { FILTERS } from '../../../../../constants';
import useMapConfig from '../../../hooks/use-map-config';
import { isSwitchFilterAvailable } from '../../../../search/helpers/switch-filter';
import DropdownFilter from './dropdown-filter';
import LocationFilter from './location-filter';
import SwitchFilter from './switch-filter';

const { STATE } = FILTERS.IDS;

const PrimaryFilters = ({ appliedFilters, availableFilters, facetedFilters, onChange, isLoading }) => {
  const { setLastFilterId } = useFilters();
  const appliedFiltersById = useMemo(() => getAppliedFilters(appliedFilters), [appliedFilters]);
  const { autosuggestEnabledSites } = useMapConfig();
  const { dropdownFilters, switchFilter, locationFilter, onFilterChange } = useFacetedFilters({
    facetedFilters,
    appliedFilters: appliedFiltersById,
    autosuggestEnabledSites,
  });

  const showSwitchFilter = isSwitchFilterAvailable(availableFilters, switchFilter);
  const handleLocationChange = (values) => {
    if (setLastFilterId) {
      setLastFilterId(STATE);
    }

    onChange(values);
  };

  return (
    <div className={`${NAMESPACE}__primary-container`}>
      <div className={`${NAMESPACE}__primary-container--left`}>
        {dropdownFilters.map(({ id, name, options, selectedOption }) => (
          <DropdownFilter
            key={id}
            name={name}
            options={options}
            selectedOption={selectedOption}
            isLoading={isLoading}
            onChange={onFilterChange(onChange, id)}
          />
        ))}

        {locationFilter && (
          <LocationFilter
            key={locationFilter.key}
            disabled={isLoading}
            name={locationFilter.name}
            placeholder={locationFilter.placeholder}
            onChange={handleLocationChange}
            selectedOption={locationFilter.id}
          />
        )}
      </div>

      {showSwitchFilter && (
        <SwitchFilter
          onChange={onFilterChange(onChange, switchFilter.id)}
          label={switchFilter.name}
          value={switchFilter.selectedOption}
          options={switchFilter.options}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

const FILTER_SHAPE = {
  id: string,
  name: string,
  value: string,
};

const FILTER_SHAPE_WITH_VALUES = {
  id: string,
  name: string,
  type: string,
  values: arrayOf(
    shape({
      id: string,
      name: string,
      url: string,
    }),
  ),
};

PrimaryFilters.propTypes = {
  appliedFilters: arrayOf(shape(FILTER_SHAPE_WITH_VALUES)).isRequired,
  availableFilters: arrayOf(shape(FILTER_SHAPE_WITH_VALUES)).isRequired,
  facetedFilters: shape({
    state: string,
    labels: shape({
      title: shape({
        only_new: string,
        operation: string,
        property: string,
      }),
    }),
    selected_filter: shape({
      operation: shape(FILTER_SHAPE),
      property: shape(FILTER_SHAPE),
      only_new: bool,
    }),
    available_filters: arrayOf(
      shape({
        ...FILTER_SHAPE,
        type: string,
        contains: arrayOf(shape({})),
      }),
    ),
  }).isRequired,
  isLoading: bool,
  onChange: func.isRequired,
};

PrimaryFilters.defaultProps = {
  isLoading: false,
};

export default PrimaryFilters;
