import React, { useContext } from 'react';
import { shape, arrayOf } from 'prop-types';

// RecommendationsFE Components
import { RecommendationsList, RecommendationsListHorizontal } from '@recommendations-fe/list';
import { RecommendationsCardHorizontalTitleFirst } from '@recommendations-fe/card';

// Search hooks
import { UserContext } from '../context/user';
import useBottomAds from '../advertising-utils/use-bottom-ads';
import IconFactory from '../icons/recommendations-fe/icon-factory';

const namespace = 'ui-search-bottom-ads';

const BottomAds = ({ request: { params }, subtitle = null, ads_to_show = null }) => {
  const { id: userId } = useContext(UserContext);

  /* istanbul ignore next */
  const callbackNoRecos = () => {
    const wrapper = document?.querySelector(`#${namespace}__wrapper`);

    if (wrapper) {
      wrapper.style.display = 'none';
    }
  };

  return (
    <div id={`${namespace}__wrapper`} className={`${namespace}__wrapper`}>
      <div className={`${namespace}__container`}>
        <RecommendationsList
          ListComponent={RecommendationsListHorizontal}
          listType="horizontal"
          Card={RecommendationsCardHorizontalTitleFirst}
          IconFactory={IconFactory}
          paddingHorizontal={12}
          onCarouselIsEmpty={callbackNoRecos}
          siteId={params.site_id}
          client={params.client}
          d2Id={params.d2_id}
          limit={ads_to_show}
          itemId={params.item_id}
          userId={userId}
          categoryId={params.category_id}
          subtitle={subtitle}
          hideRebates
          {...params}
        />
      </div>
    </div>
  );
};

const BottomAdsContainer = () => {
  const bottomAds = useBottomAds();

  if (!bottomAds) {
    return null;
  }

  return <BottomAds {...bottomAds} />;
};

BottomAds.propTypes = {
  ads_to_show: arrayOf(shape({})),
  request: shape({}).isRequired,
  subtitle: shape({}),
};

export default BottomAdsContainer;
