import { useContext } from 'react';

import { FullFreeshippingContext } from '../context/full-freeshipping';

const useFullFreeshipping = () => {
  const {
    currentFullFreeshipping,
    isLoading,
    setFullFreeshipping,
    setFullFreeShippingVisibility,
    setFullFreeShippingLoading,
  } = useContext(FullFreeshippingContext);

  return {
    currentFullFreeshipping,
    isLoading,
    setFullFreeshipping,
    setFullFreeShippingVisibility,
    setFullFreeShippingLoading,
  };
};

export default useFullFreeshipping;
