/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { elementType, node, string } from 'prop-types';

import { loadable } from 'nordic/lazy';

const AsyncGoogleMaps = loadable.lib(() => import('../../../lib/google-maps-loader'));

const Initializer = ({ children, apiKey, language, ErrorComponent, GMapsLoader }) => {
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loader = new GMapsLoader({ apiKey, language });
    const markAsReady = () => setIsReady(true);

    loader.load().then(markAsReady).catch(setError);
  }, []);

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return isReady ? children : null;
};

const Loader = ({ children, apiKey, language, ErrorComponent }) => (
  <AsyncGoogleMaps>
    {(mod) => (
      <Initializer GMapsLoader={mod.Loader} apiKey={apiKey} language={language} ErrorComponent={ErrorComponent}>
        {children}
      </Initializer>
    )}
  </AsyncGoogleMaps>
);

Loader.propTypes = {
  apiKey: string.isRequired,
  children: node.isRequired,
  ErrorComponent: elementType.isRequired,
  language: string,
};

export default Loader;
