import { useCallback, useContext, useState } from 'react';

import AutocompleteUrlService from '../../services/autocomplete';
import { StaticPropsContext } from '../components/context/static-props';

const useAutocompleteUrl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { siteId } = useContext(StaticPropsContext);

  const doAutocomplete = useCallback(
    (value) => {
      setIsLoading(true);

      return AutocompleteUrlService.getAutocompleteUrl(value, siteId)
        .then(({ data }) => data)
        .catch(() => [])
        .then((data) => {
          setIsLoading(false);

          return data;
        });
    },
    [setIsLoading, siteId],
  );

  return [isLoading, doAutocomplete];
};

export default useAutocompleteUrl;
