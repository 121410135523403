import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';

import Link from '../link/filter-link';

const namespace = 'ui-search-vehicle-body-item';

const VehicleBodyItem = ({ value, image }) => (
  <li className={namespace}>
    <Link className={`${namespace}__link`} href={value.url} title={value.name}>
      <div className={`${namespace}__image-container`}>
        <div className={`${namespace}__overlay-image`} />
        {image && (
          <Image
            preload
            mounted
            carousel
            className={classnames(`${namespace}__image`, `ui-search-vehicle-body-image--${value.id}`)}
            src={image}
            alt={value.name}
          />
        )}
      </div>
      <h3 className={`${namespace}__name`}>{value.name}</h3>
    </Link>
  </li>
);

VehicleBodyItem.propTypes = {
  image: string.isRequired,
  value: shape({
    id: string.isRequired,
    name: string.isRequired,
    url: string.isRequired,
  }),
};

export default VehicleBodyItem;
