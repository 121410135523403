import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';

import useFullFreeshipping from './use-full-freeshipping';
import FullTopEmpty from './full-shipping-empty.desktop';
import FullFreeshipping from './full-shipping.mobile';

const FullFreeshippingContainer = ({ disabledByCookie = '' }) => {
  const { currentFullFreeshipping } = useFullFreeshipping();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 500);
  }, [currentFullFreeshipping]);

  if (!currentFullFreeshipping) {
    return null;
  }

  if (
    currentFullFreeshipping.progress_bar &&
    currentFullFreeshipping.progress_bar.color === 'neutral' &&
    currentFullFreeshipping.progress_bar.percent === 0
  ) {
    return isOpen && <FullTopEmpty disabledByCookie={disabledByCookie} />;
  }

  return <FullFreeshipping location="up" />;
};

FullFreeshippingContainer.propTypes = {
  disabledByCookie: string.isRequired,
};

export default FullFreeshippingContainer;
