import { useContext } from 'react';

import { SuggestContext } from '../context/suggest-cpg';

const useSuggestions = () => {
  const { openSuggestions, setOpenSuggestions, suggestions, cartFetching } = useContext(SuggestContext);

  return { openSuggestions, setOpenSuggestions, suggestions, cartFetching };
};

export default useSuggestions;
