/* eslint-disable jsx-a11y/prefer-tag-over-role */
import React from 'react';

import { bool, func, number, string } from 'prop-types';
import useMapConfig from '../../hooks/use-map-config';
import Link from '../../../link';
import IconClose from '../../../icons/close';

const Header = ({ className, clusterSize, isClusterSelected, onUnselectCluster }) => {
  const { badgeText: resultsText, clusterText } = useMapConfig();

  const handleClick = (event) => {
    event.preventDefault();

    onUnselectCluster();
  };

  return (
    <div className={className}>
      {isClusterSelected ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link role="button" onClick={handleClick} title={clusterText}>
          <span>{`${clusterSize} ${clusterText}`}</span>
          <IconClose />
        </Link>
      ) : (
        resultsText
      )}
    </div>
  );
};

Header.propTypes = {
  className: string,
  clusterSize: number,
  isClusterSelected: bool,
  onUnselectCluster: func.isRequired,
};

Header.defaultProps = {
  clusterSize: 0,
  isClusterSelected: false,
};

export default Header;
