import React from 'react';
import { func } from 'prop-types';

import usePagination from '../use-pagination';

const Container = ({ Content }) => {
  const pagination = usePagination();
  const hasPagination = pagination?.showPagination;

  return hasPagination ? <Content {...pagination} /> : null;
};

Container.propTypes = {
  Content: func.isRequired,
};

export default Container;
