import { useContext } from 'react';

import { useSearch } from '../../hooks/context';
import { StaticPropsContext } from '../context/static-props';

const useCarousel = () => {
  const { carousel } = useSearch();
  const { lowEnd } = useContext(StaticPropsContext);

  return lowEnd ? null : carousel;
};

export default useCarousel;
