import React from 'react';

import Tooltip from '@andes/tooltip';

import Link from '../../../../../link/filter-link';
import FiltersColors from '../../../filters-colors/filters-colors.desktop';
import { colorValuesPropTypes } from '../../propTypes';

const RenderColorsValues = ({ namespace, id, name, results, url }) => (
  <li className={`${namespace}-dd ${namespace}-dd-color`} key={id} aria-label={name} id={name}>
    <Tooltip side="bottom" content={results} title={name} className={`${namespace}-tooltip`} mouseLeaveDelay={0}>
      <Link href={url} aria-label={`${name} ${results}`} name={name} title={name}>
        <FiltersColors name={name} id={id} />
      </Link>
    </Tooltip>
  </li>
);

RenderColorsValues.propTypes = {
  ...colorValuesPropTypes,
};

export default RenderColorsValues;
