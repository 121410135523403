import { useLayout, useFilters } from './context';
import { DISPLAY_TYPES, FILTERS } from '../constants';

const { MAP } = DISPLAY_TYPES;
const {
  IDS: { DISPLAY_TYPE },
} = FILTERS;
const useClientSideOptions = () => {
  const { isMapLayout = false } = useLayout();
  const { filterSearch } = useFilters();
  const isClientSideSearch = isMapLayout && !!filterSearch;

  const clientSideFilters = isClientSideSearch ? { [DISPLAY_TYPE]: MAP } : {};

  return { isClientSideSearch, clientSideFilters };
};

export default useClientSideOptions;
