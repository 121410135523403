import React, { useState } from 'react';
import { node, bool } from 'prop-types';

const EshopsContext = React.createContext({
  isEshopsEnvironment: null,
  setEshopsEnvironment: null,
});

const { Provider, Consumer: EshopsEnvironmentConsumer } = EshopsContext;

const EshopsEnvironmentProvider = ({ children, value = false }) => {
  const [isEshopsEnvironment, setEshopsEnvironment] = useState(value);

  const state = {
    isEshopsEnvironment,
    setEshopsEnvironment,
  };

  return <Provider value={state}>{children}</Provider>;
};

EshopsEnvironmentProvider.propTypes = {
  children: node.isRequired,
  value: bool,
};

export { EshopsContext, EshopsEnvironmentProvider, EshopsEnvironmentConsumer };
