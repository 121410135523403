/* global meli_ga  */

const SET = 'set';
const SEND = 'send';
const EVENT = 'event';
const PAGEVIEW = 'pageview';

// This is hardcoded into the frontend-analytics package config file
const URL_CUSTOM_DIMENSION = 'dimension110';

const isGAEnabled = () => typeof meli_ga !== 'undefined';

/**
 * Track event
 *
 * @param {String} category
 * @param {String} action
 * @param {String} label
 * @param {Object} options
 *
 * @example
 * trackEvent({
 *     category: 'CHECKOUT',
 *     action: 'VALID_CREDIT_CARD',
 *     label: 'CUSTOM_LABEL',
 *     options: '{"nonInteraction": 1}',
 * });
 */
const trackEvent = ({ category = null, action = null, label = null, options = null } = {}) => {
  // Check meli_ga is available, do nothing for now
  if (!isGAEnabled()) {
    return;
  }

  // If one of this parameters is empty do not track
  if (!category || !action) {
    return;
  }

  // Send event track
  if (!label && !options) {
    meli_ga(SEND, EVENT, category, action);
  } else if (label && !options) {
    meli_ga(SEND, EVENT, category, action, label);
  } else if (!label && options) {
    meli_ga(SEND, EVENT, category, action, options);
  } else {
    meli_ga(SEND, EVENT, category, action, label, options);
  }
};

/**
 * Track pageview
 *
 * @param {Object} options
 */

const trackPageview = (options = {}) => {
  if (isGAEnabled()) {
    meli_ga(SEND, {
      hitType: PAGEVIEW,
      ...options,
    });
  }
};

/**
 * Update the analytics url custom dimension. Useful when dealing with
 * client side routing.
 *
 * @param {String} url
 */
const updateURL = (url) => {
  if (isGAEnabled()) {
    meli_ga(SET, URL_CUSTOM_DIMENSION, url);
  }
};

module.exports = {
  trackEvent,
  trackPageview,
  updateURL,
};
