import React from 'react';

import { VERTICAL_TYPE_RES } from '../../layout/renderer/constants';
import { ResContent } from '../../layout/renderer/map/vertical-content/res-content';
import { ResContent as ResContentV2 } from './res-content';
import { DEVICE_TYPES } from '../../../constants';

const { MOBILE } = DEVICE_TYPES;

const verticalContentRenderer = (item, deviceType, version = 1) => {
  const { vertical } = item;

  switch (vertical) {
    case VERTICAL_TYPE_RES: {
      if (version === 2 && deviceType === MOBILE) {
        return <ResContentV2 item={item} />;
      }

      return <ResContent item={item} device={deviceType} />;
    }

    default:
      return null;
  }
};

export { verticalContentRenderer };
