import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import AnimatedSwitch from '../../../../animated-switch/animated-switch';
import { NAMESPACE } from '../constants';

const SwitchFilter = ({ isLoading, label, onChange, options, value }) => {
  const isChecked = !!value;

  const handleClick = () => {
    const valueToNotify = isChecked ? null : options[1].value;

    onChange(null, valueToNotify);
  };

  return (
    <div className={`${NAMESPACE}__switch`}>
      <AnimatedSwitch
        checked={isChecked}
        onClick={isLoading ? null : handleClick}
        size="small"
        updateOnCheckedChange
        usePreventDefault={isLoading}
      />

      <span className={`${NAMESPACE}__switch-label`}>{label}</span>
    </div>
  );
};

SwitchFilter.propTypes = {
  isLoading: bool,
  label: string.isRequired,
  options: arrayOf(
    shape({
      value: string.isRequired,
      name: string,
    }),
  ).isRequired,
  value: string,
  onChange: func.isRequired,
};

SwitchFilter.defaultProps = {
  isLoading: false,
  value: null,
};

export default SwitchFilter;
