import React, { useContext, useState } from 'react';

import { SuggestContext } from '../../../context/suggest-cpg';
import ServiceSuggestions from '../service/suggestions';
import { sendTracks } from '../utils';
import { useSearch } from '../../../../hooks/context';

const useSuggestions = () => {
  const [firstSessionHit, setFirstSessionHit] = useState([]);
  const [showSuggestionsIfCurrentlyVisible, setShowSuggestionsIfCurrentlyVisible] = useState(false);
  const [showSuggestionsIfCurrentlyHidden, setShowSuggestionsIfCurrentlyHidden] = useState(false);
  const [shouldShowSuggestionsOnSessionFirstCartAdd, setShouldShowSuggestionsOnSessionFirstCartAdd] = useState(false);

  const { getLoadedSuggestions, getSuggestionsAlreadyOpened, setLoadedSuggestions, setSuggestionsAlreadyOpened } =
    useSearch();

  const { currentItemSuggestion, openSuggestions, setCurrentItemSuggestion, setOpenSuggestions, setSuggestions } =
    useContext(SuggestContext);

  const showSuggestions = (itemId, showSuggestionsIfVisible, showSuggestionsIfHidden) => {
    if (getSuggestionsAlreadyOpened(itemId)) {
      setOpenSuggestions(openSuggestions ? showSuggestionsIfVisible : showSuggestionsIfHidden);
    } else {
      setOpenSuggestions(true);
    }

    setCurrentItemSuggestion(itemId);
    setSuggestionsAlreadyOpened(itemId, true);
  };

  const shouldOpenSuggestions = (
    itemCount,
    itemId,
    reqQuantity,
    showSuggestionsIfVisible,
    showSuggestionsIfHidden,
    showSuggestionsOnSessionFirstCartAdd,
  ) => {
    if (reqQuantity === 0) {
      setSuggestionsAlreadyOpened(itemId, false);
      setOpenSuggestions(false);

      return;
    }

    if (reqQuantity === 1 && (itemCount === 0 || !itemCount)) {
      showSuggestions(itemId, showSuggestionsIfVisible, showSuggestionsIfHidden);

      return;
    }

    if (!firstSessionHit?.includes(itemId)) {
      setFirstSessionHit([...firstSessionHit, itemId]);

      if (shouldShowSuggestionsOnSessionFirstCartAdd || showSuggestionsOnSessionFirstCartAdd) {
        showSuggestions(itemId, showSuggestionsIfVisible, showSuggestionsIfHidden);
      }
    } else if (
      (currentItemSuggestion === itemId && (showSuggestionsIfCurrentlyVisible || showSuggestionsIfVisible)) ||
      (!openSuggestions && (showSuggestionsIfCurrentlyHidden || showSuggestionsIfHidden))
    ) {
      showSuggestions(itemId, showSuggestionsIfVisible, showSuggestionsIfHidden);
    } else {
      setSuggestionsAlreadyOpened(itemId, false);
      setOpenSuggestions(false);
    }
  };

  const suggestionsCallback = (
    itemCount,
    itemId,
    SuggestionsResponse,
    showSuggestionsIfVisible,
    showSuggestionsIfHidden,
    showSuggestionsOnSessionFirstCartAdd,
    reqQuantity,
  ) => {
    if (SuggestionsResponse?.tags?.length > 0) {
      setSuggestions(SuggestionsResponse);

      setLoadedSuggestions(itemId, SuggestionsResponse);

      setShowSuggestionsIfCurrentlyVisible(showSuggestionsIfVisible);

      setShowSuggestionsIfCurrentlyHidden(showSuggestionsIfHidden);

      setShouldShowSuggestionsOnSessionFirstCartAdd(showSuggestionsOnSessionFirstCartAdd);

      sendTracks(SuggestionsResponse, itemId);

      shouldOpenSuggestions(
        itemCount,
        itemId,
        reqQuantity,
        showSuggestionsIfVisible,
        showSuggestionsIfHidden,
        showSuggestionsOnSessionFirstCartAdd,
      );
    } else {
      setOpenSuggestions(false);
    }
  };

  const getSuggestions = (category, itemCount, itemId, newQuantity, queryParams) => {
    const loadedSuggestions = getLoadedSuggestions(itemId);

    if (loadedSuggestions) {
      setSuggestions(loadedSuggestions);
      shouldOpenSuggestions(
        itemCount,
        itemId,
        newQuantity,
        showSuggestionsIfCurrentlyVisible,
        showSuggestionsIfCurrentlyHidden,
        shouldShowSuggestionsOnSessionFirstCartAdd,
      );
    } else {
      ServiceSuggestions(itemCount, itemId, category, queryParams, suggestionsCallback, newQuantity);
    }
  };

  return {
    getSuggestions,
  };
};

export default useSuggestions;
