/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import get from 'lodash/get';
import has from 'lodash/has';

import ItemShippingGroup from '../../../../card/shipping';
import ItemSpotlightGroup from '../../../../card/spotlight';
import ItemMeliCoin from '../../../../card/melicoin';
import ItemRebates from '../../../../card/rebates';
import ItemHighlight from '../../../../card/highlight/highlight-label';
import Reviews from '../../../../reviews/reviews';
import Group from '../../../../card/group/group';
import VariationsPicker from '../../../../card/variations/variations-picker';
import VariationsPill from '../../../../card/variations/variations-pill';
import PdsBestPrice from '../../../../card/pds/pds-best-price';
import PdsOptions from '../../../../card/pds/pds-options';
import PillsBuilder from '../../../../pills-builder';
import Pill from '../../../../pill/Pill';
import PromiseMessage from '../../../../card/promise-message';
import FreeShippingThresholdText from '../../../../free-shipping-threshold-label';
import { normalizePictureData, getEstimatedCardContentWidth } from '../../utils';
import { CARD_DATA_GROUPS, GRID, STACK, CONTENT_KEYS, VERTICAL_TYPE_CPG } from '../../constants';
import { useSearch } from '../../../../../hooks/context';
import {
  renderTitle,
  renderBrand,
  renderOfficialStore,
  renderPriceGroup,
  renderVerticalHighlightGroup,
  renderAddToCartComponent,
  ContentSkeleton,
} from '../common';
import WinnerAlternatives from '../../../../winner-alternatives/winner-alternatives';

const renderTitleGroup = (item, options, layout, includeReviews) => (
  <Group noSeparator name={CARD_DATA_GROUPS.TITLE}>
    {renderBrand(item.brand_discoverability?.text)}
    {renderTitle(item.title, layout, item.permalink, item.target, item.is_ad, item.title_compats)}
    {renderOfficialStore(item.vertical, item.official_store, options, item.is_ad)}
    {includeReviews && item.reviews && (
      <Reviews
        rating={item.reviews.rating_average}
        stars={item.reviews.rating_stars}
        formattedTotal={item.reviews.formatted_total_label || item.reviews.formatted_total}
        formattedAverage={item.reviews.formatted_rating_average || item.reviews.rating_average_formatted}
        accesibilityText={item?.reviews?.accessibility_text || item.reviews.alt_text}
      />
    )}
  </Group>
);

const renderHighlightInfo = (item) => {
  const itemHighlightInfo = get(item, 'item_highlight', get(item, 'highlight'));

  return itemHighlightInfo && <ItemHighlight {...itemHighlightInfo} />;
};

const renderVariationsPill = (item) =>
  item?.variations_picker?.pill && <VariationsPill {...item.variations_picker.pill} />;

const renderShippingGroup = (item, layout) => {
  const itemTags = get(item, 'tags');
  const contentWidth = getEstimatedCardContentWidth('desktop', layout);

  return <ItemShippingGroup shipping={item.shipping} tags={itemTags} contentWidth={contentWidth} />;
};

const renderPromiseLabel = (manufacturing_time) =>
  manufacturing_time ? <PromiseMessage manufacturing_time={manufacturing_time} /> : null;

const renderSpotlight = (item, position = null) => {
  const spotlight = get(item, 'spotlight', []);
  const filteredSpotlight = spotlight.filter((s) => (position ? s.position === position : !s.position));

  return filteredSpotlight.length > 0 ? <ItemSpotlightGroup spotlight={filteredSpotlight} /> : null;
};
const renderMeliCoinGroup = (item, layout) =>
  item.melicoin && <ItemMeliCoin {...item.melicoin} contentWidth={layout === GRID ? 190 : 264} />;

const renderDetailsGroup = (item) =>
  item.details && (
    <Group noSeparator name={CARD_DATA_GROUPS.DETAILS}>
      <span className="ui-search-item__details">{item.details}</span>
    </Group>
  );

const renderVariationPicker = (options, layout, itemContext) => {
  const { middlewareItem, variations, selectedVariation } = itemContext;

  return (
    variations.length > 1 &&
    selectedVariation && (
      <VariationsPicker
        layout={layout}
        itemContext={itemContext}
        normalizePictureData={normalizePictureData}
        renderOptions={options}
        {...middlewareItem.variations_picker}
      />
    )
  );
};

const renderReviewsGroup = (item) =>
  item.reviews && (
    <Group noSeparator name={CARD_DATA_GROUPS.REVIEWS}>
      <Reviews
        rating={item.reviews.rating_average}
        stars={item.reviews.rating_stars}
        formattedTotal={item.reviews.formatted_total_label || item.reviews.formatted_total}
        formattedAverage={item.reviews.formatted_rating_average || item.reviews.rating_average_formatted}
        accesibilityText={item?.reviews?.accessibility_text || item.reviews.alt_text}
      />
    </Group>
  );

const renderPdsInfoGroup = (item) =>
  item.pds_info && (
    <Group noSeparator name={CARD_DATA_GROUPS.PDS}>
      <PdsBestPrice {...item.pds_info.best_price} />
      <PdsOptions {...item.pds_quantity_value} />
    </Group>
  );

const renderVariationsTextGroup = (item) =>
  item?.variations?.label && (
    <Group noSeparator name={CARD_DATA_GROUPS.VARIATIONS_TEXT}>
      <span className="ui-search-item__variations-text">{item.variations.label}</span>
    </Group>
  );

const Pills = (pills, l) => <>{pills && pills.length > 0 && <PillsBuilder pills={pills} l={l} />}</>;

const renderPills = ({ pills = [] }, l) => Pills(pills, l);

const renderBottomPills = ({ bottom_pills = [] }, l) => Pills(bottom_pills, l);

const renderPillPromotions = ({ promotions }, layout) => (
  <>{promotions?.length > 0 && promotions.map((element) => <Pill element={element} layout={layout} />)}</>
);

const renderRebates = (item, layout) =>
  item?.rebates?.length > 0 && <ItemRebates rebates={item.rebates} contentWidth={layout === GRID ? 206 : 260} />;

const renderFreeShippingThMessage = (item, layout) =>
  item.threshold_label &&
  item.vertical !== VERTICAL_TYPE_CPG && (
    <FreeShippingThresholdText
      groupBy={item.group_by}
      layout={layout}
      thresholdLabel={item.threshold_label}
      thresholdLabelState={item.threshold_label_state}
    />
  );

const renderWinnerAlternativesOrPrice = (item, options, layout) => {
  if (has(item, 'product.alternatives')) {
    return (
      <WinnerAlternatives
        className="ui-search-winner-alternatives__container--right-space"
        alternatives={item.product.alternatives}
        layout={layout}
      />
    );
  }

  return renderPriceGroup(item, options);
};

const renderBottomWinnerAlternatives = (item, layout) =>
  has(item, 'product.bottom_alternatives') && (
    <WinnerAlternatives
      className="ui-search-winner-alternatives__container--top-space"
      layout={layout}
      alternatives={item.product.bottom_alternatives}
    />
  );

const getContentProps = ({ platform, layout = [STACK], item, options, itemContext }) => {
  const config = {
    SHOPS: {
      [GRID]: {
        topComponents: [
          renderPills(item, layout),
          renderTitleGroup(item, options, layout),
          renderSpotlight(item),
          renderWinnerAlternativesOrPrice(item, options, layout),
          renderRebates(item, layout),
          renderShippingGroup(item, layout),
          renderPromiseLabel(item.manufacturing_time ? item.manufacturing_time : null),
          renderVerticalHighlightGroup(item),
          renderDetailsGroup(item),
          renderPdsInfoGroup(item),
          renderVariationsTextGroup(item),
          renderBottomPills(item, layout),
        ],
      },
      [STACK]: {
        extraPadding: item.price ? !!item.price.original_price : false,
        topComponents: [renderPills(item, layout), renderTitleGroup(item, options, layout)],
        leftColComponents: [
          renderSpotlight(item),
          renderWinnerAlternativesOrPrice(item, options),
          renderRebates(item, layout),
          renderSpotlight(item, 'bottom'),
          renderShippingGroup(item, layout),
          renderBottomPills(item, layout),
        ],
        rightColComponents: [
          renderReviewsGroup(item),
          renderVariationsTextGroup(item),
          renderVariationPicker(options, layout, itemContext),
          renderAddToCartComponent(item),
        ],
      },
    },
    DEFAULT: {
      [GRID]: {
        topComponents: [
          renderVariationsPill(item),
          renderHighlightInfo(item),
          renderTitleGroup(item, options, layout, true),
          renderSpotlight(item),
          renderWinnerAlternativesOrPrice(item, options, layout),
          renderMeliCoinGroup(item, layout),
          renderPillPromotions(item, layout),
          renderPills(item, layout),
          renderRebates(item, layout),
          renderShippingGroup(item, layout),
          renderSpotlight(item, 'bottom'),
          renderPromiseLabel(item.manufacturing_time ? item.manufacturing_time : null),
          renderVerticalHighlightGroup(item),
          renderDetailsGroup(item),
          renderPdsInfoGroup(item),
          renderVariationsTextGroup(item),
          renderBottomPills(item, layout),
          renderBottomWinnerAlternatives(item, layout),
        ],
      },
      [STACK]: {
        extraPadding: item.price ? !!item.price.original_price : false,
        topComponents: [renderHighlightInfo(item), renderTitleGroup(item, options, layout)],
        leftColComponents: [
          renderSpotlight(item),
          renderWinnerAlternativesOrPrice(item, options),
          renderPdsInfoGroup(item),
          renderMeliCoinGroup(item, layout),
          renderPillPromotions(item, layout),
          renderPills(item, layout),
          renderRebates(item, layout),
          renderShippingGroup(item, layout),
          renderSpotlight(item, 'bottom'),
          renderPromiseLabel(item.manufacturing_time ? item.manufacturing_time : null),
          renderVerticalHighlightGroup(item),
          renderDetailsGroup(item),
          renderBottomPills(item, layout),
        ],
        rightColComponents: [
          renderReviewsGroup(item),
          renderVariationsTextGroup(item),
          renderVariationPicker(options, layout, itemContext),
          renderFreeShippingThMessage(item, layout),
          renderAddToCartComponent(item),
        ],
        bottomComponents: [renderBottomWinnerAlternatives(item, layout)],
      },
    },
  };

  return config[platform][layout];
};

function renderContent(item, options, layout, itemContext) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { shops } = useSearch();
  const {
    PLATFORM: { SHOPS, DEFAULT },
  } = CONTENT_KEYS;

  const platform = Boolean(shops) ? SHOPS : DEFAULT;
  const getProps = getContentProps({
    item,
    options,
    layout,
    itemContext,
    platform,
  });

  return <ContentSkeleton {...getProps} />;
}

export default renderContent;

export {
  renderContent,
  renderVariationPicker,
  renderTitleGroup,
  renderHighlightInfo,
  renderShippingGroup,
  renderPromiseLabel,
  renderDetailsGroup,
  renderReviewsGroup,
  renderVariationsTextGroup,
  renderRebates,
  renderMeliCoinGroup,
  renderPillPromotions,
  renderPills,
  renderBottomPills,
  renderFreeShippingThMessage,
  renderPdsInfoGroup,
};
