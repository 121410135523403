import { useContext } from 'react';

import { SnackbarContext } from '../context/snackbar';

const useSnackbar = () => {
  const { currentSnackbar, setSnackbar } = useContext(SnackbarContext);

  return { currentSnackbar, setSnackbar };
};

export default useSnackbar;
