import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import classNames from 'classnames';

import useTopKeywords from './use-top-keywords';

const namespace = 'ui-search-top-keywords';

const TopKeywords = ({ title, keywords = [], className = null }) => {
  if (!keywords.length) {
    return null;
  }

  const classes = classNames(namespace, className);
  const listItems = keywords.map((keyword) => (
    <li className={`${namespace}__item`} key={keyword.label}>
      <a className={`${namespace}__link`} href={keyword.url}>
        {keyword.label}
      </a>
    </li>
  ));

  return (
    <section className={classes}>
      <ul className={`${namespace}__list`}>
        <li className={`${namespace}__item`}>{title}</li>
        {listItems}
      </ul>
    </section>
  );
};

TopKeywords.propTypes = {
  className: string,
  keywords: arrayOf(
    shape({
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
  title: string.isRequired,
};

const TopKeywordsContainer = () => {
  const top_keywords = useTopKeywords();

  return <TopKeywords {...top_keywords} />;
};

export default TopKeywordsContainer;
export { TopKeywords };
