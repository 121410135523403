/* eslint-disable react-hooks/exhaustive-deps */
import { Children, cloneElement, memo, useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { string, element } from 'prop-types';

const OverlayViewPortal = ({ id, children }) => {
  const container = useRef(document.getElementById(id) || document.createElement('div'));
  const [dynamic] = useState(!container.current.parentElement);
  // When we do calculations before the actual dom node is painted,
  // some methods (e.g. getBoundingClientRect) will return incorrect
  // results, so we use this flag as a workaround to notify
  // the portal's children when the content is actually rendered.
  const [isMountedInRealDom, setIsMountedInRealDom] = useState(false);

  useEffect(() => {
    if (dynamic) {
      container.current.id = id;
      document.body.appendChild(container.current);
      setIsMountedInRealDom(true);
    }

    return () => {
      if (dynamic && container.current.parentElement) {
        container.current.parentElement.removeChild(container.current);
      }
    };
  }, [id, dynamic, setIsMountedInRealDom]);

  return createPortal(cloneElement(Children.only(children), { isMountedInRealDom }), container.current);
};

OverlayViewPortal.propTypes = {
  children: element.isRequired,
  id: string.isRequired,
};

export default memo(OverlayViewPortal);
