import { useContext } from 'react';

import { SearchContext } from '../../components/context/search';

const useSearch = () => {
  const context = useContext(SearchContext) || {};

  return context;
};

export default useSearch;
