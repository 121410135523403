const getSwitchFilterValue = (switchFilter = {}) => {
  const [, on] = switchFilter.options || [];

  return on?.value || null;
};

const getSwitchFilterLabel = (facetedFilters) => facetedFilters.labels.title.only_new;

const isSameId =
  (expected) =>
  ({ id }) =>
    expected === id;

const findById = (filters, { id: filterId, options: [, { value }] }) => {
  const { values = [] } = filters.find(isSameId(filterId)) || {};

  return values.find(isSameId(value));
};

const isSwitchFilterAvailable = (availableFilters, switchFilter, { strict = true } = {}) => {
  const hasMinOptions = switchFilter && switchFilter.options.length === 2;
  const isAvailable = strict
    ? hasMinOptions && (switchFilter.selectedOption || findById(availableFilters, switchFilter))
    : hasMinOptions;

  return !!isAvailable;
};

export { getSwitchFilterLabel, getSwitchFilterValue, isSwitchFilterAvailable };
