import React from 'react';
import { shape, string, bool, number } from 'prop-types';
import { Image } from 'nordic/image';
import get from 'lodash/get';
import { namespace } from '../../constants';

const MainImage = ({ item: { title, pictures }, options }) => (
  <Image
    alt={title}
    className={`${namespace}__main-image-internal`}
    src={get(pictures, 'stack.retina') || get(pictures, 'stack.normal')}
    width={get(pictures, 'stack.tags.width')}
    height={get(pictures, 'stack.tags.heigth')}
    {...options}
  />
);

MainImage.propTypes = {
  item: shape({
    pictures: shape({
      retina: string,
      normal: string,
      tags: shape({
        width: number,
        height: number,
      }),
    }).isRequired,
    title: string.isRequired,
  }).isRequired,
  options: shape({
    mounted: bool,
    lazyload: string,
    lowEnd: bool,
  }),
};

MainImage.defaultProps = {
  options: {},
};

export { MainImage };
