import React from 'react';
import { arrayOf, string, shape } from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';
import get from 'lodash/get';
import Card from '@andes/card';

import Link from '../../link';
import ReputationScale from '../../reputation-scale/reputation-scale';

const namespace = 'ui-search-banner';

const EshopBanner = ({ className, descriptions, title, logo, reputation, view_more }) => (
  <div className={`${namespace}__wrapper`}>
    <Card className={classnames(namespace, className)}>
      <div className={`${namespace}__logo`}>
        <Image alt={title.text} className={`${namespace}__image`} src={get(logo, 'pictures.2x')} lowEnd />
      </div>
      {reputation && (
        <div className={`${namespace}__first-block`}>
          <p className={`${namespace}-reputation__title`}>{reputation.title}</p>
          <ReputationScale currentLevelID={reputation.level} />
          {reputation.text && (
            <p className={classnames(`ui-search-color--${reputation.text.color}`, `${namespace}-reputation__text`)}>
              {reputation.text.text}
            </p>
          )}
        </div>
      )}
      {!!descriptions.length && (
        <div className={`${namespace}__second-block`}>
          {descriptions.map(
            (description) =>
              description && (
                <p
                  className={classnames(`${namespace}__description`)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: description.text }}
                />
              ),
          )}
          {view_more && (
            <Link className={`${namespace}__description`} href={view_more.target} title={view_more?.label?.text}>
              {view_more.label.text}
            </Link>
          )}
        </div>
      )}
    </Card>
  </div>
);

EshopBanner.propTypes = {
  className: string,
  descriptions: arrayOf(
    shape({
      text: string,
    }),
  ),
  logo: shape({
    pictures: shape({
      '2x': string.isRequired,
    }),
  }).isRequired,
  reputation: shape({
    title: string,
    level: string,
    text: shape({
      text: string,
      color: string,
    }),
  }),
  title: shape({
    text: string,
  }).isRequired,
  view_more: shape({
    target: string,
    label: shape({
      text: string,
    }),
  }),
};

EshopBanner.defaultProps = {
  className: null,
  descriptions: [],
  reputation: null,
  view_more: null,
};

export default EshopBanner;
