/* eslint-disable jsx-a11y/prefer-tag-over-role */
/**
 * Module dependencies
 */
import React from 'react';
import { string, func, bool } from 'prop-types';

import cx from 'classnames';

import { ARROW_DIRECTIONS } from '../constants';
import Link from '../../link';
import { useSearch } from '../../../hooks/context';

const { BACK, NEXT } = ARROW_DIRECTIONS;

const Title = ({ title }) => <span className="andes-pagination__arrow-title">{title}</span>;

const ArrowButton = ({ disabled, direction, title, handleChange, href }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <li
      className={cx('andes-pagination__button', `andes-pagination__button--${direction}`, {
        'andes-pagination__button--disabled': disabled,
        'shops__pagination-button': isShops,
      })}
    >
      <Link
        href={href}
        role="button"
        className={cx('andes-pagination__link', { 'shops__pagination-link': isShops })}
        onClick={handleChange}
        title={title}
      >
        {direction === NEXT && <Title title={title} />}
        <span className="andes-pagination__arrow" />
        {direction === BACK && <Title title={title} />}
      </Link>
    </li>
  );
};

ArrowButton.propTypes = {
  direction: string.isRequired,
  disabled: bool,
  handleChange: func,
  href: string,
  title: string,
};

Title.propTypes = {
  title: string.isRequired,
};

ArrowButton.defaultProps = {
  disabled: false,
  href: '',
  title: '',
  handleChange: null,
};

export default ArrowButton;
