import React, { useState } from 'react';
import { node, shape, string, oneOf, func } from 'prop-types';

const SnackbarContext = React.createContext('');
const { Provider, Consumer: SnackbarConsumer } = SnackbarContext;

const SnackbarProvider = ({ children, snackbar = null }) => {
  const [currentSnackbar, setSnackbar] = useState(snackbar);

  const state = {
    currentSnackbar,
    setSnackbar,
  };

  return <Provider value={state}>{children}</Provider>;
};

SnackbarProvider.propTypes = {
  children: node.isRequired,
  snackbar: shape({
    className: string,
    color: oneOf(['green', 'red']),
    message: string.isRequired,
    action: shape({
      text: string,
      onClick: func,
      position: string,
    }),
  }),
};

export { SnackbarContext, SnackbarProvider, SnackbarConsumer };
