import React, { useContext } from 'react';
import { bool } from 'prop-types';

import { StaticPropsContext } from '../context/static-props';
import IconFactory from '../icons/recommendations-fe/icon-factory';
import getBrandsAdsLayout from './helpers';

const BrandAdsDesktop = (props) => {
  const { lowEnd } = useContext(StaticPropsContext);
  const BrandAdsComponent = getBrandsAdsLayout(props);

  return <BrandAdsComponent {...props} IconFactory={IconFactory} lowEnd={lowEnd} />;
};

BrandAdsDesktop.propTypes = {
  lowEnd: bool,
};

BrandAdsDesktop.defaultProps = {
  lowEnd: false,
};

export default BrandAdsDesktop;
