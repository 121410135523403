import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Dropdown, { DropdownItem } from '@andes/dropdown';

import { NAMESPACE } from '../constants';

const DropdownFilter = ({ name, options, selectedOption, onChange, isLoading }) => (
  <Dropdown
    className={`${NAMESPACE}__dropdown`}
    menuAlignment="bottom"
    label={name}
    value={selectedOption}
    onChange={onChange}
    menuMaxHeight={465}
  >
    {options.map(({ value, name: optionName }) => (
      <DropdownItem key={value} value={value} title={optionName} disabled={isLoading} />
    ))}
  </Dropdown>
);

DropdownFilter.propTypes = {
  isLoading: bool,
  name: string.isRequired,
  options: arrayOf(
    shape({
      value: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
  selectedOption: string,
  onChange: func.isRequired,
};

DropdownFilter.defaultProps = {
  selectedOption: undefined,
  isLoading: false,
};

export default DropdownFilter;
