const restclient = require('../client/restclient')({
  timeout: 5000,
});

const API_HOST = 'https://www.portalinmobiliario.com/api';

class Service {
  static getAutocompleteUrl(value, siteId) {
    const path = `/search-faceted/${siteId}/locations`;
    const query = `query=${value}`;

    return restclient
      .get(`${API_HOST}${path}?${query}`, {
        headers: {
          Encoding: 'UTF-8',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response);
  }
}

module.exports = Service;
module.exports.restclient = restclient;
