import { useFilters } from './context';
import useClientSideOptions from './use-client-side-options';

const useFilterSearch = (href, id) => {
  const { isClientSideSearch } = useClientSideOptions();
  const { filterSearch } = useFilters();

  return isClientSideSearch ? filterSearch(href, id) : null;
};

export default useFilterSearch;
