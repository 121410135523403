import React, { useContext } from 'react';

import { loadable } from 'nordic/lazy';
import classnames from 'classnames';

import useHeader from './use-header';
import { LayoutContext } from '../context/layout';
import { HEADER_TYPES } from './constants';

const namespace = 'ui-search-header';

const ChunkHeaderModal = () => {
  const { currentLayout } = useContext(LayoutContext);
  const headerProps = useHeader();

  const OfficialStoreHeader = loadable(async () =>
    import(/* webpackChunkName: "chunk-header-official-store" */ './official-store/official-store-header.desktop'),
  );
  const DealHeader = loadable(async () => import(/* webpackChunkName: "chunk-header-deal" */ './deal/deal-header'));
  const ExhibitorHeader = loadable(async () =>
    import(/* webpackChunkName: "chunk-header-exhibitor" */ './exhibitor/exhibitor-header.desktop'),
  );

  if (!headerProps) {
    return null;
  }

  const getHeaderByType = (type) => {
    switch (type) {
      case HEADER_TYPES.OFFICIAL_STORE:
        return OfficialStoreHeader;
      case HEADER_TYPES.DEAL:
        return DealHeader;
      case HEADER_TYPES.EXHIBITOR:
        return ExhibitorHeader;

      default:
        return null;
    }
  };

  const HeaderComponent = getHeaderByType(headerProps.type);

  return (
    HeaderComponent && (
      <HeaderComponent className={classnames(namespace, `ui-search-header--${currentLayout.id}`)} {...headerProps} />
    )
  );
};

export default ChunkHeaderModal;
