import React from 'react';
import { string, shape } from 'prop-types';

import Script from 'nordic/script';
import classnames from 'classnames';

const DESKTOP_PLATFORM = 'desktop';

const UI_SEARCH_SIDEBAR_PLACEMENTS = 'ui-search-sidebar-placements';
const UI_SEARCH_PLACEMENTS = 'ui-search-placements';

const AdvertisingAdn = ({ title, first_ad, content }) => {
  const {
    queries: { platform },
  } = content;

  const namespace = platform === DESKTOP_PLATFORM ? UI_SEARCH_SIDEBAR_PLACEMENTS : UI_SEARCH_PLACEMENTS;
  const isFirstAd = first_ad ?? true;

  const contentClass = classnames(`${namespace} title-placements`, {
    last__banner: isFirstAd === false,
  });
  const [adLoaded, setAdLoaded] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const loaded = () => setAdLoaded(true);
    const node = ref.current;

    node.addEventListener('adn-banner-loaded', loaded);

    return () => {
      node.removeEventListener('adn-banner-loaded', loaded);
    };
  }, []);

  return (
    <div className={`${namespace}__display`}>
      <div className={contentClass}>
        {adLoaded && isFirstAd && (
          <div className={`${namespace}__title-container`}>
            <span className={`${namespace}__title`}>{title}</span>
          </div>
        )}
        <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
        <div data-component="adn" data-content={JSON.stringify(content)} ref={ref} />
      </div>
    </div>
  );
};

AdvertisingAdn.propTypes = {
  content: shape({
    category: string.isRequired,
    page: string.isRequired,
    placement: string.isRequired,
    platform: string.isRequired,
    site_id: string.isRequired,
    slot_id: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  title: string.isRequired,
};

export default AdvertisingAdn;
