import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { element, func, oneOfType, string, shape, number } from 'prop-types';

import { StaticPropsContext } from '../../context/static-props';

const namespace = 'ui-search-layout';

const GridRows = ({ itemComponent: LayoutItem, item, layout, index }) => {
  const { lowEnd } = useContext(StaticPropsContext);
  const options = useMemo(() => ({ lowEnd, verboseLabels: false, preload: false }), [lowEnd]);
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);

    const resizeListener = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  const MAX_GRID_HEIGHT_320 = 220;
  const MAX_GRID_HEIGHT_360 = 250;

  const imageGridHeight = windowWidth && windowWidth < 330 ? MAX_GRID_HEIGHT_320 : MAX_GRID_HEIGHT_360;

  return (
    <li className={`${namespace}__item`}>
      <LayoutItem layout={layout} item={item} index={index} options={options} imageGridHeight={imageGridHeight} />
    </li>
  );
};

GridRows.propTypes = {
  index: number,
  itemComponent: oneOfType([element, func]).isRequired,
  item: shape(),
  layout: string.isRequired,
};

GridRows.defaultProps = {
  index: null,
  item: {},
};

export default GridRows;
