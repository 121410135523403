import React, { useContext, useState } from 'react';
import { string, shape } from 'prop-types';

import classNames from 'classnames';

import { useSearch } from '../../hooks/context';
import { UserContext } from '../context/user';
import { StaticPropsContext } from '../context/static-props';
import IconBookmark from '../icons/bookmark';
import APIService from '../../../services/bookmarks';
import { trackEvent } from '../../lib/analytics';

const namespace = 'ui-search-bookmark';

const redirect = (loginUrl) => {
  window.location.href = loginUrl.replace('loginType=explicit', 'loginType=favorite');
};

const Bookmark = ({ className = '', item = {} }) => {
  const { bookmarked, id, item_id, vertical, product } = item;

  const [bookmarkState, setBookmarkState] = useState(!!bookmarked);
  const [isFetching, setIsFetching] = useState(false);
  const { loggedIn, loginUrl } = useContext(UserContext);
  const { toggleBookmark, analytics_track, bookmark } = useSearch();
  const { isHoverDisabled } = useContext(StaticPropsContext);
  const [showOnFocus, setFocusVisible] = useState(isHoverDisabled);

  const itemId = id || item_id || null;

  const getGALabel = () => {
    try {
      if (analytics_track?.pathFromRoot?.length > 0 && analytics_track.pathFromRoot.slice(-1)[0].name) {
        return analytics_track.pathFromRoot.slice(-1)[0].name.replaceAll(',', '').replaceAll(' ', '_').toUpperCase();
      }
    } catch (error) {
      return '';
    }

    return '';
  };
  const handleBookmarkFocus = () => {
    if (!isHoverDisabled) {
      setFocusVisible(!showOnFocus);
    }
  };

  const handleBookmark = async (ev) => {
    ev.preventDefault();

    if (isFetching) return;

    if (!loggedIn) {
      redirect(loginUrl);
      return;
    }
    const newBookmarkState = toggleBookmark(itemId);

    if (newBookmarkState && vertical === 'CPG') {
      trackEvent({
        category: 'SUPERMERCADO',
        action: 'ADD_FAVORITE',
        label: (product ? 'PDP_' : 'VIP_') + getGALabel(),
      });
    }

    setBookmarkState(!bookmarkState);
    setIsFetching(true);

    try {
      await APIService.toggleBookmarkService(itemId, !bookmarkState);
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        redirect(loginUrl);
        return;
      }

      setBookmarkState(!newBookmarkState);
      toggleBookmark(itemId);
    } finally {
      setIsFetching(false);
    }
  };

  const iconFilledClassNames = classNames(`${namespace}__icon-bookmark-fill`, {
    [`${namespace}__icon-bookmark-fill--active`]: bookmarkState,
  });

  return (
    <form
      action={`/search/bookmarks/${itemId}`}
      className={classNames(namespace, className, {
        [`${namespace}--active`]: bookmarkState,
        [`${namespace}--hover-disabled`]: showOnFocus,
      })}
      method="POST"
      onSubmit={async (ev) => handleBookmark(ev)}
      onFocus={handleBookmarkFocus}
      onBlur={handleBookmarkFocus}
    >
      <button
        type="submit"
        className={`${namespace}__btn`}
        role="switch"
        aria-checked={bookmarkState}
        aria-label={bookmark?.label?.text}
      >
        <IconBookmark className={`${namespace}__icon-bookmark`} />
        <IconBookmark className={iconFilledClassNames} />
      </button>
      <input type="hidden" name="logginUrl" value={loginUrl} />
      <input type="hidden" name="method" value={bookmarkState ? 'remove' : 'add'} />
      <input type="hidden" name="itemId" value={itemId} />
    </form>
  );
};

Bookmark.propTypes = {
  className: string,
  item: shape().isRequired,
};

export default Bookmark;
