/**
 * Module dependencies
 */

import React from 'react';
import { any, bool, node, objectOf, shape, string } from 'prop-types';

import classnames from 'classnames';
import { Script } from 'nordic/script';
import { Style } from 'nordic/style';
import { MeliGA } from 'nordic/analytics/meli-ga';
import RedirectApp from 'redirect-app-web';
import { Hotjar } from 'nordic/hotjar';
import { Qualtrics } from 'qualtrics-wrapper';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import { BottomSheetProvider } from 'eshops-components-library';

import { StaticPropsProvider } from '../../../../../components/context/static-props';
import { UserProvider } from '../../../../../components/context/user';
import { SearchProvider } from '../../../../../components/context/search';
import { LayoutProvider } from '../../../../../components/context/layout';
import { SnackbarProvider } from '../../../../../components/context/snackbar';
import { SuggestProvider } from '../../../../../components/context/suggest-cpg';
import { FiltersProvider } from '../../../../../components/context/filters';
import { EshopsEnvironmentProvider } from '../../../../../components/context/eshop-environment';
import { FullFreeshippingProvider } from '../../../../../components/context/full-freeshipping';
import { FreeShippingThProvider } from '../../../../../components/context/free-shipping-th';
import Metadata from '../../../../../components/metadata';
import historyFactory from '../../../../../lib/history';
import GoogleTagManager from '../../../../../components/google-tag-manager';
import { LayoutFadeProvider } from '../../../../../components/context/layout-fade/layout-fade';
import Schema from '../../../../../components/schema/schema';
import useHotjarActionsByCookie from '../../../../../hooks/use-hotjar-actions-by-cookie';
import useLocationSearch from '../../../../../hooks/use-location-search';
import ErrorBoundary from '../../../../../components/error/error-boundary';
import { hasChangeLayout } from '../../../helpers/layout-options';
import { getEmbeddedData } from '../../../helpers/getEmbeddedData';
import WrapperEshops from '../../../../../components/eshops/wrapper-eshop';
import { SVG_SPRITES as svgSprites } from './constants';
import { InterventionProvider } from '../../../../../components/context/intervention/intervention';

const history = historyFactory();

const namespace = 'ui-search';

/**
 * Search Component
 */

const Search = ({
  children,
  country,
  currentUser = null,
  deviceType,
  deviceOs,
  initialState: serverState,
  queryParams = {},
  billboardState = null,
  imagePrefix = null,
  lowEnd,
  site,
  siteId,
  cookies = {},
  visualId = '',
  platformId = '',
  groupSite = '',
  isHoverDisabled = false,
  hotjar = {},
  mapApiKey = '',
  openInApp = null,
  nonce,
}) => {
  const { error, initialState, isLoading, ...locationSearchProps } = useLocationSearch({
    history,
    serverState,
  });

  const { vertical, shops, storefront: sF } = initialState;
  const hasEshopData = sF?.appearance?.hidden === false && sF?.appearance?.children?.length > 0;
  const staticProps = {
    visualId,
    site,
    siteId,
    shops,
    lowEnd,
    deviceType,
    deviceOs,
    country,
    queryParams,
    billboardState,
    imagePrefix,
    changeLayout: hasChangeLayout(initialState.layout_options),
    vertical,
    // @TODO: remove this with Catalog Highlight
    maxViewsCatalogTooltip: 20,
    maxViewsTooltip: 20,
    isHoverDisabled,
    mapApiKey,
    hotjar,
  };

  const isShops = Boolean(shops);
  const embeddedData = getEmbeddedData(initialState, currentUser);

  useHotjarActionsByCookie(hotjar);

  const isPolycards = !!initialState.polycard_context;

  initialState.intervention_info = {};

  return (
    <div className={classnames(namespace, { 'shops__ui-main': isShops })}>
      {openInApp?.shouldRedirect && <RedirectApp {...openInApp} />}
      <Style href={`search.${deviceType}${groupSite}.css`} critical={deviceType === 'mobile'} />
      {isPolycards && <Style href="search.card.polycard.css" critical={deviceType === 'mobile'} />}
      {!isPolycards && deviceType === 'mobile' && initialState.layout_options.current === 'stack' && (
        <Style href="search.mobile.card.stack.css" critical />
      )}
      {serverState.find_user_location && <Script src="location.js" />}
      {initialState?.ads_metadata?.track_url && (
        <Script>
          {`
        var trackMclics = document.createElement('img');
        trackMclics.src = '${initialState.ads_metadata.track_url}';
      `}
        </Script>
      )}
      <Schema {...initialState.seo} />
      <SearchProvider
        {...initialState}
        history={history}
        isLoadingResults={isLoading}
        locationSearchError={error}
        {...locationSearchProps}
      >
        <LayoutProvider layout={initialState.layout_options} history={history}>
          <UserProvider value={currentUser}>
            <StaticPropsProvider value={staticProps}>
              <EshopsEnvironmentProvider value={hasEshopData}>
                <BottomSheetProvider>
                  <SnackbarProvider>
                    <FiltersProvider history={history}>
                      <SuggestProvider>
                        <FullFreeshippingProvider
                          value={initialState?.cart_info?.free_shipping}
                          shimmer={initialState?.show_free_shipping_bar_shimmer}
                          shimmerAction={initialState?.show_free_shipping_bar_shimmer_action}
                        >
                          <InterventionProvider interventionData={initialState?.intervention_info}>
                            <FreeShippingThProvider>
                              <LayoutFadeProvider>
                                <>
                                  <Hydrator ssrOnly>{svgSprites}</Hydrator>
                                  {hasEshopData && <WrapperEshops {...sF.appearance} siteId={siteId} />}
                                  {children}
                                  <Metadata {...initialState.seo} {...initialState.canonical_info} />
                                </>
                              </LayoutFadeProvider>
                            </FreeShippingThProvider>
                          </InterventionProvider>
                        </FullFreeshippingProvider>
                      </SuggestProvider>
                    </FiltersProvider>
                  </SnackbarProvider>
                </BottomSheetProvider>
              </EshopsEnvironmentProvider>
            </StaticPropsProvider>
          </UserProvider>
        </LayoutProvider>
      </SearchProvider>
      {/** @TODO all data shold be part of initialState */}
      {deviceType !== 'mobile' && <MeliGA {...initialState.analytics_track} />}
      {/** @TODO path should be part of initialState.melidata_track */}
      <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
        {`window.viewability.setNonceForCSP('${nonce}')`}
      </Script>
      <Hotjar {...hotjar} />
      {!initialState.shops && (
        <GoogleTagManager
          siteId={siteId}
          results={initialState.results}
          platform={platformId}
          cookies={cookies}
          queryInitialState={initialState.query}
          businessInitialState={initialState.analytics_track.business}
          pageVerticalInitialState={initialState.analytics_track.pageVertical}
        />
      )}
      {site === 'PI' && (
        <Script
          src="https://3e31081d8fc9.edge.sdk.awswaf.com/3e31081d8fc9/4a188c736202/challenge.js"
          crossOrigin={null}
          defer
        />
      )}
      <Qualtrics interceptId="ZN_efi6I3nkl8bGcTA" embeddedData={embeddedData} />
    </div>
  );
};

Search.propTypes = {
  billboardState: shape({}),
  children: node.isRequired,
  cookies: objectOf(any),
  country: shape({
    decimal_separator: string.isRequired,
    thousands_separator: string.isRequired,
  }).isRequired,
  currentUser: shape({
    loggedIn: bool.isRequired,
    loginUrl: string.isRequired,
    nickname: string.isRequired,
  }),
  deviceOs: string.isRequired,
  deviceType: string.isRequired,
  domain: string.isRequired,
  groupSite: string,
  hotjar: shape({}),
  imagePrefix: string,
  initialState: objectOf(any).isRequired,
  isHoverDisabled: bool,
  lowEnd: bool.isRequired,
  mapApiKey: string,
  nonce: string,
  openInApp: shape({
    shouldRedirect: bool,
  }),
  platformId: string,
  queryParams: objectOf(any),
  site: string,
  siteId: string.isRequired,
  visualId: string,
  webp: bool,
};

const SearchContainer = (props) => (
  <ErrorBoundary siteId={props.siteId}>
    <Search {...props} />
  </ErrorBoundary>
);

SearchContainer.propTypes = {
  siteId: string.isRequired,
};

/**
 * Inject i18n context as props into Search.
 */
export default SearchContainer;
