import React, { useState } from 'react';
import { node } from 'prop-types';

const LayoutFadeContext = React.createContext();
const { Provider, Consumer: LayoutFadeConsumer } = LayoutFadeContext;

const LayoutFadeProvider = ({ children }) => {
  const [isFade, setIsFade] = useState(false);

  const state = {
    isFade,
    setIsFade,
  };

  return <Provider value={state}>{children}</Provider>;
};

LayoutFadeProvider.propTypes = {
  children: node.isRequired,
};

export { LayoutFadeContext, LayoutFadeProvider, LayoutFadeConsumer };
