import React from 'react';

import { LazyHydrate as Hydrator } from 'nordic/lazy';
import classnames from 'classnames';

import { DEVICE_TYPES } from '../../../../constants';
import SuggestionsContainer from '../../../suggestions/suggestions.mobile';
import { isIntervention } from '.';
import { renderInterventionsMobile } from '../../../intervention/utils/render-interventions/interventions.mobile';
import { renderInterventionsDesktop } from '../../../intervention/utils/render-interventions/interventions.desktop';
import { useSearch } from '../../../../hooks/context';

const { MOBILE } = DEVICE_TYPES;
const COMPATS_INTERVENTION = 'COMPATS_INTERVENTION';
const namespaceCompats = 'ui-compats-wrapper';

const renderOneColumnComponents = (item, index, { LayoutItem, deviceType, options, namespace, layout, isPolycard }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const renderResults = (renderItem, renderIndex) => {
    if (isIntervention(item)) {
      return <Hydrator whenVisible>{renderInterventionsDesktop[item]({ item, deviceType })}</Hydrator>;
    }

    return (
      <li
        key={renderItem.id}
        className={classnames(
          `${namespace}__item`,
          'shops__layout-item',
          { 'shops__layout-item': isShops },
          { [`${namespace}__stack`]: !isPolycard },
        )}
      >
        <LayoutItem layout={layout} item={renderItem} index={renderIndex} options={options} />
      </li>
    );
  };

  const renderMobileComponents = (internalItem, internalIndex) => {
    if (isIntervention(internalItem)) {
      const interventionElementMobile = (
        <Hydrator whenVisible>
          {renderInterventionsMobile[internalItem.id]({ item: internalItem, position: internalIndex })}
        </Hydrator>
      );

      return interventionElementMobile;
    }

    return internalItem && renderResults(internalItem, internalIndex);
  };

  const renderDesktopComponents = (internalItem, internalIndex) => {
    if (isIntervention(internalItem)) {
      if (internalItem.id === 'CAROUSEL_INTERVENTION' && internalIndex !== 0) {
        internalItem.content.classname = 'mt-16';
      }

      const interventionElementDesktop = (
        <Hydrator whenVisible noWrapper>
          {renderInterventionsDesktop[internalItem.id]({ item: internalItem, deviceType })}
        </Hydrator>
      );

      const classNameCompats = classnames({
        [`${namespaceCompats}__item`]:
          internalItem.id === COMPATS_INTERVENTION && item.type === 'compats_widget_sticky',
      });

      return <li className={classNameCompats}>{interventionElementDesktop}</li>;
    }

    return internalItem && renderResults(internalItem, internalIndex);
  };

  return deviceType === MOBILE ? renderMobileComponents(item, index) : renderDesktopComponents(item, index, deviceType);
};

const renderOneColumnStackComponents = (
  item,
  index,
  { LayoutItem, currentItemSuggestion, results, layout, namespace, options, openSuggestions },
) => {
  const renderSuggestions = (element) => {
    if (!currentItemSuggestion) {
      return null;
    }

    if (currentItemSuggestion === results[`${element}`].id) {
      return <SuggestionsContainer />;
    }

    return null;
  };

  const renderResults = (itemResults, indexResult) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { shops } = useSearch();
    const isShops = Boolean(shops);

    itemResults.verboseLabels = true;
    itemResults.resultIndex = indexResult;

    return (
      <>
        <li key={itemResults.id} className={classnames(`${namespace}__item`, { 'shops__row-item': isShops })}>
          <LayoutItem item={itemResults} layout={layout} options={options} index={indexResult} />
        </li>
        {openSuggestions && renderSuggestions(indexResult)}
      </>
    );
  };

  const renderComponents = (internalItem, internalIndex) => {
    if (isIntervention(internalItem)) {
      const interventionElementMobile = (
        <Hydrator whenVisible noWrapper>
          {renderInterventionsMobile[internalItem.id]({ item: internalItem, position: internalIndex })}
        </Hydrator>
      );

      const classNameCompats = classnames({
        [`${namespaceCompats}__item`]:
          internalItem.id === COMPATS_INTERVENTION && item.type === 'compats_widget_sticky',
      });

      return <li className={classNameCompats}>{interventionElementMobile}</li>;
    }

    return internalItem && renderResults(internalItem, internalIndex);
  };

  return renderComponents(item, index);
};

export { renderOneColumnComponents, renderOneColumnStackComponents };
