import React, { memo, useEffect, useState } from 'react';
import { arrayOf, number, element, func, shape, oneOfType } from 'prop-types';

import cx from 'classnames';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';

import { NAMESPACE, STOPPED_SPEED, ANIMATED_SPEED } from './constants';

const isValidPage = (selectedItemIndex, results) => {
  const hasValue = selectedItemIndex !== null;
  const inRange = selectedItemIndex >= 0 && selectedItemIndex < results.length;

  return hasValue && inRange;
};

const Carousel = ({ ItemComponent, results, onSlideChange, selectedItemIndex }) => {
  const [hasSliderAnimation, toggleSliderAnimation] = useState(false);
  const hasItemSelected = isValidPage(selectedItemIndex, results);

  useEffect(() => toggleSliderAnimation(hasItemSelected), [hasItemSelected]);

  if (results.length === 0) {
    return null;
  }

  return (
    <div className={cx(NAMESPACE, { [`${NAMESPACE}--show`]: hasItemSelected })}>
      <div className={`${NAMESPACE}__carousel-container`}>
        <CarouselSnapped
          spacing={0}
          slidesToShow={1}
          infinite={false}
          lazyload={false}
          arrows={false}
          pagination={false}
          strictBoundaries={false}
          scroll="visible"
          initialSlide={selectedItemIndex}
          afterChange={onSlideChange}
          speed={hasSliderAnimation ? ANIMATED_SPEED : STOPPED_SPEED}
        >
          {results.map((item) => (
            <CarouselSnappedSlide key={item.id}>
              <div className={`${NAMESPACE}__slide`}>
                <ItemComponent item={item} options={{ lazyload: 'on', carousel: true }} />
              </div>
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  ItemComponent: oneOfType([element, func]).isRequired,
  results: arrayOf(shape({})),
  selectedItemIndex: number,
  onSlideChange: func,
};

Carousel.defaultProps = {
  results: [],
  selectedItemIndex: null,
  onSlideChange: () => undefined,
};

export default memo(Carousel);
