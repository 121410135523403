import React, { useState } from 'react';
import { node, shape } from 'prop-types';

const SuggestContext = React.createContext('');
const { Provider, Consumer: SuggestConsumer } = SuggestContext;

const SuggestProvider = ({ children, suggest }) => {
  const [currentItemSuggestion, setCurrentItemSuggestion] = useState(suggest);
  const [openSuggestions, setOpenSuggestions] = useState(suggest);
  const [suggestions, setSuggestions] = useState(suggest);
  const [cartFetching, setCartFetching] = useState(suggest);

  const state = {
    openSuggestions,
    setOpenSuggestions,
    setCurrentItemSuggestion,
    currentItemSuggestion,
    suggestions,
    setSuggestions,
    cartFetching,
    setCartFetching,
  };

  return <Provider value={state}>{children}</Provider>;
};

SuggestProvider.propTypes = {
  children: node.isRequired,
  suggest: shape({}),
};

export { SuggestContext, SuggestProvider, SuggestConsumer };
